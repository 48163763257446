import React, { useState, useEffect } from "react";
import { Link, Route, Routes, useLocation } from "react-router-dom";

import UserProfileTab from "./userProfileTab";

import Lightbox from "react-18-image-lightbox";
import "react-18-image-lightbox/style.css";
import { storeApi, common } from "../../../api/index";
import default_store_img from "../../../assets/images/default_profile_black_v3.png";
import default_back_img from "../../../assets/images/default_back_img.png";
import { useParams } from "react-router-dom";
import StoreDetails from "./StoreDetails";
import Offers from "./offers";
import AddBalance from "./addBalance";
import { Alert, Typography } from "@material-tailwind/react";
import { useToast } from "@chakra-ui/react";

export default function Index() {
  const [proImg, setProImg] = useState("");
  const [backImg, setBackImg] = useState("");
  const [storeInfo, setStoreInfo] = useState({});
  const { status, id } = useParams();
  const [city, setCity] = useState("");
  const [openAlert, setOpenAlert] = useState(false);
  const [deleteAlert, setDeleteAlert] = useState(false);
  const [refreshData, setRefreshData] = useState(false);
  const toast = useToast();

  useEffect(() => {
    document.documentElement.setAttribute("dir", "ltr");
    document.documentElement.classList.add("light");
    document.documentElement.classList.remove("dark");
    storeApi
      .getProfileImg({ store_id: id })
      .then((res) => {
        setProImg(`https://api.sure2shops.com/${res.data}`);
      })
      .catch((err) => {
        setProImg(``);
      });

    storeApi
      .getBackgroundImg({ store_id: id })
      .then((res) => {
        setBackImg(`https://api.sure2shops.com/${res.data}`);
      })
      .catch((err) => {
        setBackImg(``);
      });

    if (status === "pending") {
      //search in temp store_list table
      storeApi
        .singleTempInfo({ store_id: id })
        .then((res) => {
          console.log(res.data);
          setStoreInfo(res.data);
        })
        .catch((err) => {
          console.log(err?.response?.message);
        });
    } else {
      //search in store_list table
      storeApi
        .singleStoreInfo({ store_id: id })
        .then((res) => {
          console.log(res.data);
          setStoreInfo(res.data);
        })
        .catch((err) => {
          console.log(err?.response?.message);
        });
    }
  }, [refreshData]);

  return (
    <>
      <div className="container-fluid relative px-3">
        <div className="layout-specing">
          <div className="grid grid-cols-1">
            <div className="profile-banner relative text-transparent rounded-md shadow dark:shadow-gray-700 overflow-hidden">
              <input
                id="pro-banner"
                name="profile-banner"
                type="file"
                className="hidden"
              />
              <div className="relative shrink-0">
                <img
                  src={backImg ? backImg : default_back_img}
                  className="h-80 w-full object-cover"
                  id="profile-banner"
                  alt=""
                />
                <div className="absolute inset-0 bg-black/70"></div>
                <label
                  className="absolute inset-0 cursor-pointer"
                  htmlFor="pro-banner"
                ></label>
              </div>
            </div>
          </div>

          <div className="grid md:grid-cols-12 grid-cols-1">
            <UserProfileTab
              profileImg={proImg ? proImg : default_store_img}
              storeData={storeInfo}
            />
            <Routes>
              {/* <Route exact path="/store-profile" element={<StoreDetails/>}/> */}
              <Route
                exact
                path={`/store-details`}
                element={
                  <StoreDetails
                    onRefreshData={(e) => setRefreshData(!refreshData)}
                  />
                }
              />
              <Route
                exact
                path={`/profile-offer`}
                element={
                  <Offers onRefreshData={(e) => setRefreshData(!refreshData)} />
                }
              />
              <Route
                exact
                path={`/profile-balance`}
                element={
                  <AddBalance
                    onRefreshData={(e) => setRefreshData(!refreshData)}
                  />
                }
              />
            </Routes>
          </div>
        </div>
      </div>
    </>
  );
}
