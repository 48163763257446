import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { MdKeyboardArrowRight } from "../../assets/icons/vander";
import { storeApi } from "../../api";
import DataTable from "react-data-table-component";
import { format } from "date-fns";
import { Heading, useToast } from "@chakra-ui/react";

export default function Subcription() {
  const [rq, setRq] = useState([]);
  const toast = useToast();
  const [errorMessage, setErrorMessage] = useState("");
  const [searchText, setSearchText] = useState("");

  const columns = [
    { name: "USER_ID", selector: "user_id", sortable: true },
    { name: "S_PHONE", selector: "subscription_phone_no", sortable: true },
    { name: "OFFER_ID", selector: "offer_id", sortable: true },
    {
      name: "COUPON_ID",
      selector: "coupon_id",
      sortable: true,
      cell: (row) => row.coupon_id.toString(),
    },
    { name: "STATUS", selector: "subscription_status", sortable: true },
    {
      name: "START_DATE",
      selector: "offer_start_date",
      sortable: true,
      cell: (row) =>
        format(new Date(row.offer_start_date), "yyyy-MM-dd HH:mm:ss"),
    },
    {
      name: "END_DATE",
      selector: "offer_end_date",
      sortable: true,
      cell: (row) =>
        format(new Date(row.offer_end_date), "yyyy-MM-dd HH:mm:ss"),
    },
  ];

  const filteredData = rq.filter((item) =>
    Object.values(item).some(
      (value) =>
        value !== null &&
        value.toString().toLowerCase().includes(searchText.toLowerCase())
    )
  );

  useEffect(() => {
    document.documentElement.setAttribute("dir", "ltr");
    document.documentElement.classList.add("light");
    document.documentElement.classList.remove("dark");

    // adminApi.AllBalanceOffers()
    // .then(res=>{
    //     console.log(res.data)
    //     setBalanceOfferList(res.data)
    // })
    // .catch(err=>{
    //     console.log(err?.response)
    // })

    // adminApi.AllOffers()
    // .then(res=>{
    //     console.log(res.data)
    //     setOfferList(res.data)
    // })
    // .catch(err=>{
    //     console.log(err?.response)
    // })
  }, []);

  const ToastPromise = (title, message, request) => {
    toast.promise(request, {
      success: {
        title: title,
        description: message,
        position: "top-right",
        duration: 9000,
        isClosable: true,
      },
      error: {
        title: "ERROR",
        description: errorMessage
          ? errorMessage
          : "An issue occured with your request",
        position: "top-right",
        duration: 9000,
        isClosable: true,
      },
      loading: {
        title: "Please wait",
        description: "Processing...",
        position: "top-right",
        duration: 9000,
        isClosable: true,
      },
    });
  };

  useEffect(() => {
    const myPromise = new Promise((resolve, reject) => {
      storeApi
        .AllSubscription()
        .then((res) => {
          // console.log(res.data)
          setRq(res.data);
          resolve();
        })
        .catch((err) => {
          // console.log(err?.response)
          setErrorMessage("An issue ocurred getting the coupons");
          reject();
        });
    });
    ToastPromise("Get", `All Subcriptions was fetched`, myPromise);
  }, []);

  return (
    <div className="container-fluid relative px-3">
      <div className="layout-specing">
        <div className="md:flex justify-between items-center">
          <h5 className="text-lg font-semibold">All Subcriptions</h5>

          <ul className="tracking-[0.5px] inline-flex items-center sm:mt-0 mt-3">
            <li className="inline-block capitalize text-[14px] font-bold duration-500 dark:text-white/70 hover:text-indigo-600 dark:hover:text-white">
              <Link to="/">Home</Link>
            </li>
            <li className="inline-block text-base text-slate-950 dark:text-white/70 mx-0.5 ltr:rotate-0 rtl:rotate-180">
              <MdKeyboardArrowRight />
            </li>
            <li
              className="inline-block capitalize text-[14px] font-bold text-indigo-600 dark:text-white"
              aria-current="page"
            >
              Payment request
            </li>
          </ul>
        </div>

        <div className="grid grid-cols-1 mt-6">
          <div className="rounded-md shadow dark:shadow-gray-700 p-6 bg-white dark:bg-slate-900">
            <span className="flex-inline">
              {/* <Heading as='h3' size='md' paddingBottom={5}></Heading> */}
            </span>

            <div>
              <input
                type="text"
                placeholder="Search..."
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                style={{
                  padding: "4px",
                  borderRadius: "5px",
                  border: "1px solid #ccc",
                  marginRight: "10px", // Adjust the margin as needed
                }}
              />

              <DataTable columns={columns} data={filteredData} pagination />
            </div>

            {/* <YourComponent/> */}
          </div>
        </div>
      </div>
    </div>
  );
}
