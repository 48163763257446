import http from "../http-common";


  export const createTempStore = (data) => {
    return http.post(`/storenew/createtemp`,data);
  }

  export const signInStore = (data) => {
    return http.post(`/storenew/signin`,data);
  }

    
  export const getStoreUserByToken = async (token_id) => {
    const response = await http.get(`/storenew/getStoreUserByToken/${token_id}`)
    if(response.data.store_account_id){
      let data = {
        store_account_id:response.data.store_account_id,
        store_account_username:response.data.store_account_username,
        store_account_email:response.data.store_account_email,
        store_account_phone_no:response.data.store_account_phone_no,
        store_account_type:response.data.store_account_type,
        store_id:response.data.store_id
      }
      return data;
    }
    return response.data;
  }

  export const getAllPendingStores = () => {
    return http.get(`/storenew/getAllPendingStores`);
  }

  export const AllCommnets = () => {
    return http.get(`/storenew/AllCommnets`);
  }



  export const getAllStores = () => {
    return http.get(`/storenew/Astors`);
  }

  export const AllSubscription = () => {
    return http.get(`/storenew/AllSubscription`);
  }

  export const createStore = (data) => {
    return http.post("/storenew/AproveStore",data);
  }

  export const replyComent = (data) => {
    return http.post("/storenew/replyComent",data);
  }

  export const approveIndividual = (data) => {
    return http.post("/storenew/approveIndividual",data);
  }

    // add a new subscription
  export const deleteStoretemp = (data) => {
    return http.post("/storenew/rmtempstore",data);
  }

  export const contactUs = (data) => {
    return http.post("/storenew/contactus",data);
  }


  export const uploadProfilePic = (data) => {
    return http.post("/storenew/addprofileiamge",data,{
      headers: {
        "Content-Type":"multipart/form-data",
      }
    });
  }

  export const uploadBackgroundPic = (data) => {
    return http.post("/storenew/addbackgroundimage",data,{
      headers: {
        "Content-Type":"multipart/form-data",
      }
    });
  }

  export const removeProfileImg = (data) => {
    return http.post("/storenew/rmprofileimage",data);
  }

  export const removeBackgroundImg = (data) => {
    return http.post("/storenew/rmbackgroundimage",data);
  }

  export const getProfileImg = (data) => {
    return http.post("/storenew/getimageprofile",data);
  }

  export const getBackgroundImg = (data) => {
    return http.post("/storenew/getimagebackground",data);
  }

  export const isIndividualCheck = (data) => {
    return http.post(`/storenew/isIndividual`,data);
  }

  export const setPayment = (data) => {
    return http.post(`/Account/setPayment`,data);
  }

  export const addRequestPayment = (data) => {
    return http.post(`/Account/addRequestPayment`,data);
  }

  export const addBalancePayment = (data) => {
    return http.post(`/Account/addBalancePayment`,data);
  }

  export const storeUserInfo = (data) => {
    return http.post(`/storenew/storeUserInfo`,data);
  }
  
  export const singleStoreInfo = (data) => {
    return http.post(`/storenew/singleStoreInfo`,data);
  }
  
  export const singleTempInfo = (data) => {
    return http.post(`/storenew/singleTempInfo`,data);
  }

  export const getUserStoreByStoreId = (data) => {
    return http.post(`/storenew/getUserStoreByStoreId`,data);
  }
  