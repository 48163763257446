import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { MdKeyboardArrowRight } from "../../assets/icons/vander";
import { couponApi, adminApi } from "../../api";
import DataTable from "react-data-table-component";
import { format } from "date-fns";
import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Heading,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuItemOption,
  MenuGroup,
  MenuOptionGroup,
  MenuDivider,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  useToast,
  Select,
  Text,
  Stack,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Input,
  Checkbox,
  CheckboxGroup,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";

const successInit = {
  offerId: true,
  balanceOfferId: true,
  couponCount: true,
  couponFor: true,
  couponForBalance: true,
};

export default function Coupon() {
  const [couponList, setCouponList] = useState([]);
  const [offerList, setOfferList] = useState([]);
  const [balanceOfferList, setBalanceOfferList] = useState([]);
  const [offerId, setOfferId] = useState("");
  const [balanceOfferId, setBalanceOfferId] = useState("");
  const [couponCount, setCouponCount] = useState("1");
  const [couponFor, setCouponFor] = useState("");
  const [couponForBalance, setCouponForBalance] = useState("");
  const [isMultiple, setIsMultiple] = useState(false);
  const [success, setSuccess] = useState(successInit);
  const toast = useToast();
  const [errorMessage, setErrorMessage] = useState("");
  const [refreshData, setRefreshData] = useState(false);
  const [searchText, setSearchText] = useState("");

  const columns = [
    { name: "OF_NAME", selector: "offer_name", sortable: true },
    { name: "OFFER_ID", selector: "offer_id", sortable: true },
    { name: "TYPE", selector: "coupon_type", sortable: true, with: "20px" },
    {
      name: "COUPON_ID",
      selector: "coupon_id",
      sortable: true,
      cell: (row) => row.coupon_id.toString(),
    },
    { name: "STATUS", selector: "coupon_status", sortable: true },
    {
      name: "C_D",
      selector: "coupon_creation_date",
      sortable: true,
      cell: (row) =>
        format(new Date(row.coupon_creation_date), "yyyy-MM-dd HH:mm:ss"),
    },
    {
      name: "DATE_USED",
      selector: "date_used",
      sortable: true,
      cell: (row) =>
        row.date_used
          ? format(new Date(row.date_used), "yyyy-MM-dd HH:mm:ss")
          : "N/A",
    },
    { name: "COUPON_FOR", selector: "coupon_for", sortable: true },
  ];

  const styles = {
    table: {
      fontFamily: "Arial, sans-serif",
      border: "1px solid #dcdcdc",
      borderRadius: "4px",
      overflow: "hidden",
    },
    tableHead: {
      backgroundColor: "#f4f4f4",
      color: "#333",
    },
    tableBody: {
      backgroundColor: "#fff",
    },
    tableRowHover: {
      backgroundColor: "#e0e0e0",
    },
    pagination: {
      display: "flex",
      justifyContent: "flex-end",
      padding: "10px",
    },
  };

  const filteredData = couponList.filter((item) =>
    Object.values(item).some(
      (value) =>
        value !== null &&
        value.toString().toLowerCase().includes(searchText.toLowerCase())
    )
  );

  const {
    isOpen: isOpenBalanceModal,
    onOpen: onOpenBalanceModal,
    onClose: onCloseBalanceModal,
  } = useDisclosure();

  const {
    isOpen: isOpenOfferModal,
    onOpen: onOpenOfferModal,
    onClose: onCloseOfferModal,
  } = useDisclosure();

  useEffect(() => {
    document.documentElement.setAttribute("dir", "ltr");
    document.documentElement.classList.add("light");
    document.documentElement.classList.remove("dark");

    adminApi
      .AllBalanceOffers()
      .then((res) => {
        // console.log(res.data)
        setBalanceOfferList(res.data);
      })
      .catch((err) => {
        // console.log(err?.response)
      });

    adminApi
      .AllOffers()
      .then((res) => {
        // console.log(res.data)
        setOfferList(res.data);
      })
      .catch((err) => {
        // console.log(err?.response)
      });
  }, []);

  const ToastPromise = (title, message, request) => {
    toast.promise(request, {
      success: {
        title: title,
        description: message,
        position: "top-right",
        duration: 9000,
        isClosable: true,
      },
      error: {
        title: "ERROR",
        description: errorMessage
          ? errorMessage
          : "An issue occured with your request",
        position: "top-right",
        duration: 9000,
        isClosable: true,
      },
      loading: {
        title: "Please wait",
        description: "Processing...",
        position: "top-right",
        duration: 9000,
        isClosable: true,
      },
    });
  };

  useEffect(() => {
    const myPromise = new Promise((resolve, reject) => {
      couponApi
        .AllCoupons()
        .then((res) => {
          // console.log(res.data)
          setCouponList(res.data);
          resolve();
        })
        .catch((err) => {
          // console.log(err?.response)
          setErrorMessage("An issue ocurred getting the coupons");
          reject();
        });
    });
    ToastPromise("Get", `All coupons was fetched`, myPromise);
  }, [refreshData]);

  const addBalanceCoupon = () => {
    setSuccess({
      ...success,
      couponFor: true,
      couponCount: true,
      balanceOfferId: true,
    });
    if (
      couponForBalance === "" ||
      couponCount === "" ||
      balanceOfferId === ""
    ) {
      // console.log()
      setSuccess({
        ...success,
        couponForBalance: couponForBalance !== "" ? true : false,
        couponCount: couponCount !== "" ? true : false,
        balanceOfferId: balanceOfferId !== "" ? true : false,
      });
    } else {
      let data = {
        offer_id: balanceOfferId,
        coupon_for: couponForBalance,
        number_of_coupons: couponCount ? couponCount : "1",
      };
      const myPromise = new Promise((resolve, reject) => {
        couponApi
          .NewCouponForRefill(data)
          .then((res) => {
            // console.log(res.data)
            setRefreshData(!refreshData);
            onCloseBalanceModal();
            resolve();
          })
          .catch((err) => {
            // console.log(err?.response)
            setErrorMessage("An issue ocurred with your request.");
            onCloseBalanceModal();
            reject();
          });
      });
      ToastPromise("Added", `Balance coupon was created`, myPromise);
    }
  };

  const addOfferCoupon = () => {
    setSuccess({
      ...success,
      couponFor: true,
      couponCount: true,
      offerId: true,
    });
    if (couponFor === "" || couponCount === "" || offerId === "") {
      // console.log()
      setSuccess({
        ...success,
        couponFor: couponFor !== "" ? true : false,
        couponCount: couponCount !== "" ? true : false,
        offerId: offerId !== "" ? true : false,
      });
    } else {
      let data = {
        offer_id: offerId,
        coupon_for: couponFor,
        number_of_coupons: couponCount ? couponCount : "1",
      };
      const myPromise = new Promise((resolve, reject) => {
        couponApi
          .NewCouponForOffer(data)
          .then((res) => {
            // console.log(res.data)
            setRefreshData(!refreshData);
            onCloseOfferModal();
            resolve();
          })
          .catch((err) => {
            // console.log(err?.response)
            setErrorMessage("An issue ocurred with your request.");
            onCloseOfferModal();
            reject();
          });
      });
      ToastPromise("Added", `Offer coupon was created`, myPromise);
    }
  };

  return (
    <div className="container-fluid relative px-3">
      <div className="layout-specing">
        <div className="md:flex justify-between items-center">
          <h5 className="text-lg font-semibold">Coupons</h5>

          <ul className="tracking-[0.5px] inline-flex items-center sm:mt-0 mt-3">
            <li className="inline-block capitalize text-[14px] font-bold duration-500 dark:text-white/70 hover:text-indigo-600 dark:hover:text-white">
              <Link to="/">Home</Link>
            </li>
            <li className="inline-block text-base text-slate-950 dark:text-white/70 mx-0.5 ltr:rotate-0 rtl:rotate-180">
              <MdKeyboardArrowRight />
            </li>
            <li
              className="inline-block capitalize text-[14px] font-bold text-indigo-600 dark:text-white"
              aria-current="page"
            >
              Coupons
            </li>
          </ul>
        </div>

        <div className="grid grid-cols-1 mt-6">
          <div className="rounded-md shadow dark:shadow-gray-700 p-6 bg-white dark:bg-slate-900">
            <span className="flex-inline">
              <Heading as="h3" size="md" paddingBottom={5}>
                All Coupons
              </Heading>
              <Menu>
                <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
                  Actions
                </MenuButton>
                <MenuList>
                  <MenuItem onClick={() => onOpenBalanceModal()}>
                    Add New Balance Coupon
                  </MenuItem>
                  <MenuItem onClick={() => onOpenOfferModal()}>
                    Add New Offer Coupon
                  </MenuItem>
                </MenuList>
              </Menu>
            </span>

            {/* balance modal */}
            <Modal
              isOpen={isOpenBalanceModal}
              onClose={onCloseBalanceModal}
              isCentered
            >
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>Add New Balance Coupon</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <Stack spacing={3}>
                    <FormLabel>Select balance offer</FormLabel>
                    <FormControl isInvalid={!success.balanceOfferId}>
                      <Select
                        placeholder="Select balance offer"
                        onChange={(e) => setBalanceOfferId(e.target.value)}
                      >
                        {balanceOfferList.map((offer, index) => {
                          return (
                            <option
                              key={offer.balance_offer_id}
                              value={offer.balance_offer_id}
                            >
                              {offer.balance_offer_name}
                            </option>
                          );
                        })}
                      </Select>
                      <FormErrorMessage>Offer is required</FormErrorMessage>
                    </FormControl>
                    <FormControl isInvalid={!success.couponForBalance}>
                      <FormLabel>Coupon For</FormLabel>
                      <Input
                        name="hi"
                        onChange={(e) => setCouponForBalance(e.target.value)}
                      />
                      <FormErrorMessage>
                        Coupon for is required
                      </FormErrorMessage>
                    </FormControl>
                    <Checkbox
                      isChecked={isMultiple}
                      onChange={(e) => setIsMultiple(e.target.checked)}
                    >
                      Multiple
                    </Checkbox>
                    {isMultiple ? (
                      <NumberInput
                        defaultValue={1}
                        min={1}
                        max={1000}
                        onChange={(e) => setCouponCount(e)}
                      >
                        <NumberInputField />
                        <NumberInputStepper>
                          <NumberIncrementStepper />
                          <NumberDecrementStepper />
                        </NumberInputStepper>
                      </NumberInput>
                    ) : (
                      ""
                    )}
                  </Stack>
                </ModalBody>

                <ModalFooter>
                  <Button
                    colorScheme="blue"
                    mr={3}
                    onClick={() => addBalanceCoupon()}
                  >
                    Add
                  </Button>
                  <Button onClick={onCloseBalanceModal}>Close</Button>
                </ModalFooter>
              </ModalContent>
            </Modal>

            {/* offer modal */}
            <Modal
              isOpen={isOpenOfferModal}
              onClose={onCloseOfferModal}
              isCentered
            >
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>Add New Offer Coupon</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <Stack spacing={3}>
                    <FormLabel>Select offer</FormLabel>
                    <FormControl isInvalid={!success.offerId}>
                      <Select
                        placeholder="Select offer"
                        onChange={(e) => setOfferId(e.target.value)}
                      >
                        {offerList.map((offer, index) => {
                          return (
                            <option key={offer.offer_id} value={offer.offer_id}>
                              {offer.offer_name}
                            </option>
                          );
                        })}
                      </Select>
                      <FormErrorMessage>Offer is required</FormErrorMessage>
                    </FormControl>
                    <FormControl isInvalid={!success.couponFor}>
                      <FormLabel>Coupon For</FormLabel>
                      <Input
                        name="hi2"
                        onChange={(e) => setCouponFor(e.target.value)}
                      />
                      <FormErrorMessage>
                        Coupon for is required
                      </FormErrorMessage>
                    </FormControl>
                    <Checkbox
                      isChecked={isMultiple}
                      onChange={(e) => setIsMultiple(e.target.checked)}
                    >
                      Multiple
                    </Checkbox>
                    {isMultiple ? (
                      <NumberInput
                        defaultValue={1}
                        min={1}
                        max={1000}
                        onChange={(e) => setCouponCount(e)}
                      >
                        <NumberInputField />
                        <NumberInputStepper>
                          <NumberIncrementStepper />
                          <NumberDecrementStepper />
                        </NumberInputStepper>
                      </NumberInput>
                    ) : (
                      ""
                    )}
                  </Stack>
                </ModalBody>

                <ModalFooter>
                  <Button
                    colorScheme="blue"
                    mr={3}
                    onClick={() => addOfferCoupon()}
                  >
                    Add
                  </Button>
                  <Button onClick={onCloseOfferModal}>Close</Button>
                </ModalFooter>
              </ModalContent>
            </Modal>

            <div>
              <input
                type="text"
                placeholder="Search..."
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                style={{
                  padding: "4px",
                  borderRadius: "5px",
                  border: "1px solid #ccc",
                  marginRight: "10px",
                  marginBottom: "10px",
                }}
              />

              <DataTable
                columns={columns}
                data={filteredData}
                pagination
                style={styles.table}
                theme="default"
                customStyles={{
                  headRow: styles.tableHead,
                  bodyRow: styles.tableBody,
                  pagination: styles.pagination,
                  rows: {
                    hover: styles.tableRowHover,
                  },
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
