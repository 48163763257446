// - LOGIN
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const LOGIN = 'LOGIN';
export const LOGIN_START = 'LOGIN_START';
// SIGNUP
export const SIGNUP = 'SIGNUP';

//SIGNIN
export const USERSESSION = 'USERSESSION';
export const USERPROFILE = 'USERPROFILE';
export const SET_ADMIN_STATUS = 'SET_ADMIN_STATUS';


//APPLICATION
export const CHANGE_THEME = 'CHANGE_THEME';
export const CHANGE_FONT = 'CHANGE_FONT';
export const FORCE_APPEARANCE = 'FORCE_APPEARANCE';
export const CHANGE_LANGUAGE = 'CHANGE_LANGUAGE';
export const SET_MENU = 'SET_MENU';
export const SET_INTRO = 'SET_INTRO';

//STORE AUTH
export const STORE_REGISTRATION_STATUS = 'STORE_REGISTRATION_STATUS';
export const STORESESSION = 'STORESESSION';
export const STORE_SIGN_IN = 'STORE_SIGN_IN';
export const SET_STORE_PROFILE = 'SET_STORE_PROFILE';

//admin
export const SET_ADMIN_TOKEN = 'SET_ADMIN_TOKEN';
export const SET_ADMIN_PROFILE = 'SET_ADMIN_PROFILE';