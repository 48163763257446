import http from "../http-common";



  export const signin = (data)=> {
    return http.post("/Admin/adminLogin", data);
    // if (response.data.accessToken) {
    //   return response.data.accessToken;
    // }
    // else{
    //   return response;
    // }
  }

  export const adminInfo = (data)=> {
    return http.post("/Admin/adminInfo", data);
  }

  export const checkToken = (data)=> {
    return http.post("/Admin/checkToken", data);
  }

  export const approveTempStore = (data)=> {
    return http.post("/storenew/addTempToStore", data);
  }

  export const AddBalance = (data)=> {
    return http.post("/couponAction/AddRefillPortal", data);
  }

  export const AddOffer = (data)=> {
    return http.post("/couponAction/AddOfferPortal", data);
  }

  export const AllOffers = ()=> {
    return http.get("/couponAction/AllOffers");
  }

  export const AllBalanceOffers = ()=> {
    return http.get("/couponAction/AllBalanceOffers");
  }

//   async signin(data) {
//     const response = await http.post("/Account/loginuser", data);
//     if (response.data.accessToken) {
//       localStorage.setItem("token_id", JSON.stringify(response.data.accessToken));
//     }
//     return response.data;
//   }
  
  // export const getUserByToken = async (token_id) => {
  //   const response = await http.post(`/Account/getUser/${token_id}`)
  //   if(response.data.customer_id){
  //     let data = {
  //       customer_id:response.data.customer_id,
  //       first_name:response.data.first_name,
  //       last_name:response.data.last_name,
  //       customer_account_email:response.data.customer_account_email,
  //       customer_account_phone_no:response.data.customer_account_phone_no,
  //       account_type_id:response.data.account_type_id,
  //       age:response.data.age,
  //       gender:response.data.gender,
  //       city_id:response.data.city_id,
  //       customer_account_status:response.data.customer_account_status
  //     }
  //     return data;
  //   }
  //   return response.data;
  // }
