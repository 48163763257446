import * as actionTypes from './actionTypes';

export const setAdminToken = (data) => (dispatch) => {
  dispatch(
    {
      type: actionTypes.SET_ADMIN_TOKEN,
      data,
    });
};

export const setAdminProfile = (data) => (dispatch) => {
  dispatch(
    {
      type: actionTypes.SET_ADMIN_PROFILE,
      data,
    });
};
