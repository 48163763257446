import http from "../http-common";

  // export const createTempStore = (data) => {
  //   return http.post(`/storenew/createtemp`,data);
  // }

  export const getAllBrands = () => {
    return http.get(`/cars/allbrands`);
  }

  export const getAllModels = (data) => {
    return http.post(`/cars/model`,data);
  }

  export const getAllTrims = (data) => {
    return http.post(`/cars/trim`,data);
  }

  export const getAllEngines = (data) => {
    return http.post(`/cars/engine`,data);
  }

  export const getAllCylinders = (data) => {
    return http.post(`/cars/cylinder`,data);
  }


  export const AproveVehiclePost = (data) => {
    return http.post(`/cars/AproveVehiclePost`,data);
  }

  export const RejectVehiclePostRefund = (data) => {
    return http.post(`/cars/RejectVehiclePostRefund`,data);
  }

  export const NewAdForCompany = (data) => {
    return http.post("/cars/NewAdForCompany",data,{
      headers: {
        "Content-Type":"multipart/form-data",
      }
    });
  }

  export const AllAd = () => {
    return http.get(`/cars/AllAd`);
  }

  export const HoldAd = (data) => {
    return http.post(`/cars/HoldAd`,data);
  }

  export const ShowAd = (data) => {
    return http.post(`/cars/ShowAd`,data);
  }

  export const RemoveAd = (data) => {
    return http.post(`/cars/RemoveAd`,data);
  }

  
  

  export const getAllDriveTrain = () => {
    return http.get(`/cars/allVehicleDrivetrain`);
  }

  export const getAllCityPlates = () => {
    return http.get(`/cars/allPlateCity`);
  }

  export const getAllPlateType = () => {
    return http.get(`/cars/allPlateType`);
  }

  export const getAllColors = () => {
    return http.get(`/cars/allVehicleColor`);
  }

  export const getAllSeatMaterials = () => {
    return http.get(`/cars/allSeatMaterial`);
  }

  export const getAllVehicleConditions = () => {
    return http.get(`/cars/allVehicleCondition`);
  }

  export const getAllTransmissionTypes = () => {
    return http.get(`/cars/allVehicleTransmission`);
  }

  export const getAllFuelTypes = () => {
    return http.get(`/cars/allVehicleFuelType`);
  }

  export const getAllVehicleSellLocation = () => {
    return http.get(`/cars/allVehicleCity`);
  }

  export const getAllImportCountry = () => {
    return http.get(`/cars/allVehicleImportCountry`);
  }

  export const getOtherSpecifications = () => {
    return http.get(`/cars/allVehicleEextraFuture`);
  }
  export const AllVehiclePending = () => {
    return http.get(`/cars/AllVehiclePending`);
  }

  export const submitNewCar = (data) => {
    return http.post("/cars/add-vehicle",data,{
      headers: {
        "Content-Type":"multipart/form-data",
      }
    });
  }

  export const getAllTest = () => {
    return http.get(`/cars/test`);
  }

  export const submitNewCarStore = (data) => {
    return http.post("/cars/add-vehicle-for-Store",data,{
      headers: {
        "Content-Type":"multipart/form-data",
      }
    });
  }