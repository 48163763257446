import * as actionTypes from '../actions/actionTypes';

const initialState = {
  login: {
    registered: false,
    status:"",
    number:""
  },
  storeSession:{
    token_id:"",
    store_account_id:""
  },
  access:{
    isLogin:false
  },
  storeProfile:{
    store_account_id:"",
    store_account_username:"",
    store_account_email:"",
    store_account_phone_no:"",
    store_account_type:"",
    store_id:""
  }
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case actionTypes.STORE_REGISTRATION_STATUS:
      return {
        ...state,
        login: action.data,
      };
    case actionTypes.STORESESSION:
      return {
        ...state,
        storeSession: action.data,
      };
    case actionTypes.STORE_SIGN_IN:
      return {
        ...state,
        access: action.data,
      };
    case actionTypes.SET_STORE_PROFILE:
    return {
      ...state,
      storeProfile: action.data,
    };
    default:
      return state;
  }
};
