import http from "../http-common";

  export const allrequests = () => {
    return http.get(`/Account/AllRequestPayment`);
  }

  export const rmrequest = (data) => {
    return http.post(`/Account/removeBalancePayment`,data);
  }

  export const addBalancePaymentTest = (data) => {
    return http.post(`/Account/addBalancePaymentTest`,data);
  }
  
//   export const AddOffer = (data) => {
//     return http.post(`/couponAction/AddOffer`,data);
//   }
  
//   export const AllCoupons = () => {
//     return http.get(`/couponAction/AllCoupons`);
//   }
  
//   export const NewCouponForOffer = (data) => {
//     return http.post(`/couponAction/newCouponForOffer`,data);
//   }

//   export const NewCouponForRefill = (data) => {
//     return http.post(`/couponAction/newCouponForRefill`,data);
//   }