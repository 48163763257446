import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { MdKeyboardArrowRight } from "../../assets/icons/vander";
import { carsApi } from "../../api";
import DataTable from "react-data-table-component";
import { format } from "date-fns";
import {
  Flex,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Heading,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuItemOption,
  MenuGroup,
  MenuOptionGroup,
  MenuDivider,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  useToast,
  Select,
  Text,
  Stack,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Input,
  Checkbox,
  CheckboxGroup,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Image,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  InfoIcon,
  CheckCircleIcon,
  WarningTwoIcon,
  ArrowLeftIcon,
  ArrowRightIcon,
  DeleteIcon,
  ViewOffIcon,
  ViewIcon,
} from "@chakra-ui/icons";

const successInit = {
  file: true,
  companyName: true,
  description: true,
};

export default function Ad() {
  const [file, setFile] = useState(null);
  const [companyName, setCompanyName] = useState("");
  const [description, setDescription] = useState("1");
  const [ad, setAd] = useState([]);
  const [success, setSuccess] = useState(successInit);
  const toast = useToast();
  const [errorMessage, setErrorMessage] = useState("");
  const [refreshData, setRefreshData] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [filteredData, setFilteredData] = useState(null);

  const handleCompanyNameChange = (event) => {
    setCompanyName(event.target.value);
  };

  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const columns = [
    { name: "COMPANY_NAME", selector: "company_name", sortable: true },
    { name: "DESC", selector: "description", sortable: true },
    { name: "STATUS", selector: "ad_status", sortable: true, with: "10px" },
    {
      name: "START",
      selector: "ad_start_date",
      sortable: true,
      cell: (row) => format(new Date(row.ad_start_date), "yyyy-MM-dd HH:mm:ss"),
    },
    {
      name: "END",
      selector: "ad_end_date",
      sortable: true,
      cell: (row) => format(new Date(row.ad_end_date), "yyyy-MM-dd HH:mm:ss"),
    },
    {
      name: "Actions",
      cell: (row) => (
        <div>
          <Flex justifyContent="space-around" alignItems="center">
            <InfoIcon
              w={5}
              h={5}
              color="blue.500"
              marginRight={4}
              onClick={() => showAd(row.id)}
              cursor="pointer"
            />
            {row.ad_status === "show" ? (
              <ViewOffIcon
                w={5}
                h={5}
                color="orange.400"
                marginRight={4}
                onClick={() => holdingAd(row.id)}
                cursor="pointer"
              />
            ) : (
              <ViewIcon
                w={5}
                h={5}
                color="blue.500"
                marginRight={4}
                onClick={() => showingAd(row.id)}
                cursor="pointer"
              />
            )}
            <DeleteIcon
              w={5}
              h={5}
              color="red.500"
              marginRight={4}
              onClick={() => RmAd(row.id)}
              cursor="pointer"
            />
          </Flex>
        </div>
        //     <Menu>
        //     <MenuButton as={Button} size="sm" rightIcon={<ChevronDownIcon />}>
        //         Actions
        //     </MenuButton>
        //     <MenuList>
        //         <MenuItem onClick={()=>showAd(row.id)}>Show ad</MenuItem>
        //         <MenuItem>Hide ad</MenuItem>
        //         <MenuItem>Remove ad</MenuItem>
        //         {/* <MenuItem onClick={()=>onOpenOfferModal()}>Add New Offer Coupon</MenuItem> */}
        //     </MenuList>
        // </Menu>
      ),
    },
  ];

  //   const filteredDataResult = ad.filter((item) =>
  //   Object.values(item).some(
  //     (value) =>
  //       value !== null &&
  //       value.toString().toLowerCase().includes(searchText.toLowerCase())
  //   )
  // );

  const customStyles = {
    headRow: {
      style: {
        backgroundColor: "#f0f0f0",
      },
    },
    rows: {
      style: {
        fontSize: "13px",
        "&:hover": {
          backgroundColor: "#e0e0e0",
        },
      },
    },
    headCells: {
      style: {
        fontWeight: "bold",
      },
    },
  };

  //   const filteredData = ad.filter(item =>
  //     Object.values(item).some(value =>
  //       value !== null && value.toString().toLowerCase().includes(searchText.toLowerCase())
  //     )
  //   );

  const filteredDataResult = ad.filter((item) =>
    Object.values(item).some(
      (value) =>
        value !== null &&
        value.toString().toLowerCase().includes(searchText.toLowerCase())
    )
  );

  const {
    isOpen: isOpenAdModal,
    onOpen: onOpenAdModal,
    onClose: onCloseAdModal,
  } = useDisclosure();

  const {
    isOpen: isOpenDtModal,
    onOpen: onOpenDtModal,
    onClose: onCloseDtModal,
  } = useDisclosure();

  const ToastPromise = (title, message, request) => {
    toast.promise(request, {
      success: {
        title: title,
        description: message,
        position: "top-right",
        duration: 9000,
        isClosable: true,
      },
      error: {
        title: "ERROR",
        description: errorMessage
          ? errorMessage
          : "An issue occured with your request",
        position: "top-right",
        duration: 9000,
        isClosable: true,
      },
      loading: {
        title: "Please wait",
        description: "Processing...",
        position: "top-right",
        duration: 9000,
        isClosable: true,
      },
    });
  };

  useEffect(() => {
    const myPromise = new Promise((resolve, reject) => {
      carsApi
        .AllAd()
        .then((res) => {
          // console.log(res.data);
          setAd(res.data);
          resolve();
        })
        .catch((err) => {
          // console.log(err?.response);
          setErrorMessage("An issue ocurred getting the coupons");
          reject();
        });
    });
    ToastPromise("Get", `All Ad was fetched`, myPromise);
  }, [refreshData]);

  const createAd = () => {
    setSuccess({
      ...success,
      companyName: true,
      description: true,
      file: true,
    });
    if (companyName === "" || description === "" || file === "") {
      // console.log();
      setSuccess({
        ...success,
        companyName: companyName !== "" ? true : false,
        description: description !== "" ? true : false,
        file: file !== "" ? true : false,
      });
    } else {
      const formData = new FormData();
      formData.append("company_name", companyName);
      formData.append("description", description);
      formData.append("file", file);
      // console.log(formData);
      // let data ={
      //     "company_name":companyName,
      //     "description":description,
      //     "image_url":fileImage
      // }
      const myPromise = new Promise((resolve, reject) => {
        carsApi
          .NewAdForCompany(formData)
          .then((res) => {
            // console.log(res.data);
            setRefreshData(!refreshData);
            onCloseAdModal();
            resolve();
          })
          .catch((err) => {
            // console.log(err?.response);
            setErrorMessage("An issue ocurred with your request.");
            onCloseAdModal();
            reject();
          });
      });
      ToastPromise("Added", `Ad added `, myPromise);
    }
  };

  const showAd = (id) => {
    // Filter the data based on the selected vehicle_id
    const filteredData = ad.find((item) => item.id === id);
    setFilteredData(filteredData);
    onOpenDtModal();
  };

  const holdingAd = (id) => {
    const myPromise = new Promise((resolve, reject) => {
      carsApi
        .HoldAd({ id: id })
        .then((res) => {
          // console.log(res.data);
          setRefreshData(!refreshData);
          resolve();
        })
        .catch((err) => {
          // console.log(err?.response);
          setErrorMessage("An issue ocurred with your request.");
          reject();
        });
    });
    ToastPromise("Added", `Ad stopped `, myPromise);
  };

  const showingAd = (id) => {
    const myPromise = new Promise((resolve, reject) => {
      carsApi
        .ShowAd({ id: id })
        .then((res) => {
          // console.log(res.data);
          setRefreshData(!refreshData);
          resolve();
        })
        .catch((err) => {
          // console.log(err?.response);
          setErrorMessage("An issue ocurred with your request.");
          reject();
        });
    });
    ToastPromise("Added", `Ad shwoing `, myPromise);
  };

  const RmAd = (id) => {
    const myPromise = new Promise((resolve, reject) => {
      carsApi
        .RemoveAd({ id: id })
        .then((res) => {
          // console.log(res.data);
          setRefreshData(!refreshData);
          resolve();
        })
        .catch((err) => {
          // console.log(err?.response);
          setErrorMessage("An issue ocurred with your request.");
          reject();
        });
    });
    ToastPromise("Added", `Ad removed `, myPromise);
  };

  return (
    <div className="container-fluid relative px-3">
      <div className="layout-specing">
        <div className="md:flex justify-between items-center">
          <h5 className="text-lg font-semibold">Coupons</h5>

          <ul className="tracking-[0.5px] inline-flex items-center sm:mt-0 mt-3">
            <li className="inline-block capitalize text-[14px] font-bold duration-500 dark:text-white/70 hover:text-indigo-600 dark:hover:text-white">
              <Link to="/">Home</Link>
            </li>
            <li className="inline-block text-base text-slate-950 dark:text-white/70 mx-0.5 ltr:rotate-0 rtl:rotate-180">
              <MdKeyboardArrowRight />
            </li>
            <li
              className="inline-block capitalize text-[14px] font-bold text-indigo-600 dark:text-white"
              aria-current="page"
            >
              Ad
            </li>
          </ul>
        </div>

        <div className="grid grid-cols-1 mt-6">
          <div className="rounded-md shadow dark:shadow-gray-700 p-6 bg-white dark:bg-slate-900">
            <span className="flex-inline">
              <Heading as="h3" size="md" paddingBottom={5}>
                All Ad
              </Heading>
              <Button as={Button} onClick={() => onOpenAdModal()}>
                Create Ad
              </Button>
            </span>

            {/* balance modal */}
            <Modal isOpen={isOpenAdModal} onClose={onCloseAdModal} isCentered>
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>Add ad</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <Stack spacing={3}>
                    <FormControl isInvalid={!success.file}>
                      <FormLabel>Image</FormLabel>
                      <Input type="file" onChange={handleFileChange} />
                      <FormErrorMessage>
                        please select image !!
                      </FormErrorMessage>
                    </FormControl>
                    <FormControl isInvalid={!success.companyName}>
                      <FormLabel>Company Name</FormLabel>
                      <Input name="hi" onChange={handleCompanyNameChange} />
                      <FormErrorMessage>
                        Company Name is required
                      </FormErrorMessage>
                    </FormControl>

                    <FormControl isInvalid={!success.description}>
                      <FormLabel>Description</FormLabel>
                      <Input name="hi" onChange={handleDescriptionChange} />
                      <FormErrorMessage>
                        Description is required
                      </FormErrorMessage>
                    </FormControl>
                  </Stack>
                </ModalBody>

                <ModalFooter>
                  <Button colorScheme="blue" mr={3} onClick={() => createAd()}>
                    Add
                  </Button>
                  <Button onClick={onCloseAdModal}>Close</Button>
                </ModalFooter>
              </ModalContent>
            </Modal>

            {/* ///////////////////////////////// */}

            {filteredData && (
              <Modal isOpen={isOpenDtModal} onClose={onCloseDtModal} isCentered>
                <ModalOverlay />
                <ModalContent maxW="900px">
                  <ModalHeader>Ad</ModalHeader>
                  <ModalCloseButton />
                  <ModalBody>
                    <Image
                      src={`https://api.sure2shops.com/${filteredData.image_url}`}
                      alt="Vehicle Image"
                    />
                    {/* <Image src={`http://127.0.0.1:8080/${filteredData.image_url}`} alt="Vehicle Image" /> */}
                  </ModalBody>
                </ModalContent>
              </Modal>
            )}

            {/* /////////////////////////////////////////////// */}

            <div>
              <input
                type="text"
                placeholder="Search..."
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                style={{
                  padding: "4px",
                  borderRadius: "5px",
                  border: "1px solid #ccc",
                  marginRight: "10px",
                  marginBottom: "10px",
                }}
              />

              {/* <DataTable
                            columns={columns}
                            data={filteredData}
                            pagination
                            customStyles={customStyles}
                        /> */}
              <DataTable
                columns={columns}
                data={filteredDataResult}
                pagination
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
