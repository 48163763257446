import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { MdKeyboardArrowRight } from "../../assets/icons/vander";
import { accountApi } from "../../api";
import DataTable from "react-data-table-component";
import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Heading,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuItemOption,
  MenuGroup,
  MenuOptionGroup,
  MenuDivider,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  useToast,
  Select,
  Text,
  Stack,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Input,
  Checkbox,
  CheckboxGroup,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Flex,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";

export default function Paymentrequest() {
  const [rq, setRq] = useState([]);
  const toast = useToast();
  const [errorMessage, setErrorMessage] = useState("");
  const [refreshData, setRefreshData] = useState(false);
  const [searchText, setSearchText] = useState("");

  const columns = [
    { name: "REQUEST_PHONE", selector: "request_phone", sortable: true },
    { name: "PAYMENT_TYPE", selector: "payment_type", sortable: true },
    { name: "AMOUNT", selector: "amount", sortable: true },
    { name: "REQUEST_DATE", selector: "request_date", sortable: true },
    {
      name: "Actions",
      cell: (row) => (
        <div>

          <Modal
              isOpen={isOpenApproveModal}
              onClose={onCloseApproveModal}
              isCentered
            >
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>Are you sure to Approve ?</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  
                  <Flex justifyContent="center">
                   <div style={{marginTop:"20px"}}>
                   <Button backgroundColor="green" color="white" marginRight={10} onClick={() => approveRequest(row.id)}>
                      Yes
                    </Button>
                    <Button onClick={onCloseApproveModal}>
                      No
                    </Button>
                   </div>
                  </Flex>
                </ModalBody>

                {/* <ModalFooter>
            
                  <Button onClick={onCloseApproveModal}>Close</Button>
                </ModalFooter> */}
              </ModalContent>
          </Modal>

          <button onClick={() => onOpenApproveModal()} style={{marginRight:"15px",color:"green"}}>
            Approve
          </button>

          <Modal
              isOpen={isOpenRejectModal}
              onClose={onCloseRejectModal}
              isCentered
            >
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>Are you sure to reject ?</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  
                  <Flex justifyContent="center">
                   <div style={{marginTop:"20px"}}>
                   <Button backgroundColor="orange" color="white" marginRight={10} onClick={() => rm(row.id)}>
                      Yes
                    </Button>
                    <Button onClick={onCloseRejectModal}>
                      No
                    </Button>
                   </div>
                  </Flex>
                </ModalBody>

                {/* <ModalFooter>
            
                  <Button onClick={onCloseRejectModal}>Close</Button>
                </ModalFooter> */}
              </ModalContent>
          </Modal>

          <button onClick={() => onOpenRejectModal()} style={{ color: "orange" }}>
          Reject
          </button>
        </div>
      ),
    },
  ];

  const filteredData = rq.filter((item) =>
    Object.values(item).some(
      (value) =>
        value !== null &&
        value.toString().toLowerCase().includes(searchText.toLowerCase())
    )
  );

  useEffect(() => {
    document.documentElement.setAttribute("dir", "ltr");
    document.documentElement.classList.add("light");
    document.documentElement.classList.remove("dark");

    // adminApi.AllBalanceOffers()
    // .then(res=>{
    //     console.log(res.data)
    //     setBalanceOfferList(res.data)
    // })
    // .catch(err=>{
    //     console.log(err?.response)
    // })

    // adminApi.AllOffers()
    // .then(res=>{
    //     console.log(res.data)
    //     setOfferList(res.data)
    // })
    // .catch(err=>{
    //     console.log(err?.response)
    // })
  }, []);

  const ToastPromise = (title, message, request) => {
    toast.promise(request, {
      success: {
        title: title,
        description: message,
        position: "top-right",
        duration: 9000,
        isClosable: true,
      },
      error: {
        title: "ERROR",
        description: errorMessage
          ? errorMessage
          : "An issue occured with your request",
        position: "top-right",
        duration: 9000,
        isClosable: true,
      },
      loading: {
        title: "Please wait",
        description: "Processing...",
        position: "top-right",
        duration: 9000,
        isClosable: true,
      },
    });
  };

  const getData = () => {
    const myPromise = new Promise((resolve, reject) => {
      accountApi
        .allrequests()
        .then((res) => {
          // console.log(res.data)
          setRq(res.data);
          resolve();
        })
        .catch((err) => {
          // console.log(err?.response)
          setErrorMessage("An issue ocurred getting the coupons");
          reject();
        });
    });
    ToastPromise("Get", `All requests was fetched`, myPromise);
  };


  const {
    isOpen: isOpenApproveModal,
    onOpen: onOpenApproveModal,
    onClose: onCloseApproveModal,
  } = useDisclosure();

  const {
    isOpen: isOpenRejectModal,
    onOpen: onOpenRejectModal,
    onClose: onCloseRejectModal,
  } = useDisclosure();


  useEffect(() => {
    const myPromise = new Promise((resolve, reject) => {
      accountApi
        .allrequests()
        .then((res) => {
          // console.log(res.data)
          setRq(res.data);
          resolve();
        })
        .catch((err) => {
          // console.log(err?.response)
          setErrorMessage("An issue ocurred getting the coupons");
          reject();
        });
    });
    ToastPromise("Get", `All requests was fetched`, myPromise);
  }, [refreshData]);

  const approveRequest = (id) => {
    // console.log(id)
    const myPromise = new Promise((resolve, reject) => {
      accountApi
        .addBalancePaymentTest({ rq_id: id })
        .then((res) => {
          // console.log(res.message)
          accountApi.rmrequest({ id: id }).then((res) => {
            onCloseApproveModal()
            getData();
          });
          resolve();
        })
        .catch((err) => {
          // console.log(err?.response)
          setErrorMessage("An issue ocurred getting the coupons");
          reject();
        });
    });
    ToastPromise("approved", `balance added`, myPromise);
  };

  const rm = (id) => {
    // console.log(id)
    const myPromise = new Promise((resolve, reject) => {
      accountApi
        .rmrequest({ id: id })
        .then((res) => {
          // console.log(res.message)
          onCloseRejectModal()
          getData();
          resolve();
        })
        .catch((err) => {
          // console.log(err?.response)
          setErrorMessage("An issue ocurred getting the coupons");
          reject();
        });
    });
    ToastPromise("removed", `request removed`, myPromise);
  };

  return (
    <div className="container-fluid relative px-3">
      <div className="layout-specing">
        <div className="md:flex justify-between items-center">
          <h5 className="text-lg font-semibold">Request to add balance</h5>

          <ul className="tracking-[0.5px] inline-flex items-center sm:mt-0 mt-3">
            <li className="inline-block capitalize text-[14px] font-bold duration-500 dark:text-white/70 hover:text-indigo-600 dark:hover:text-white">
              <Link to="/">Home</Link>
            </li>
            <li className="inline-block text-base text-slate-950 dark:text-white/70 mx-0.5 ltr:rotate-0 rtl:rotate-180">
              <MdKeyboardArrowRight />
            </li>
            <li
              className="inline-block capitalize text-[14px] font-bold text-indigo-600 dark:text-white"
              aria-current="page"
            >
              Payment request
            </li>
          </ul>
        </div>

        <div className="grid grid-cols-1 mt-6">
          <div className="rounded-md shadow dark:shadow-gray-700 p-6 bg-white dark:bg-slate-900">
            <span className="flex-inline">
              <Heading as="h3" size="md" paddingBottom={5}>
                All Payment Requests
              </Heading>
            </span>

            <div>
              <input
                type="text"
                placeholder="Search..."
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                style={{
                  padding: "4px",
                  borderRadius: "5px",
                  border: "1px solid #ccc",
                  marginRight: "10px", // Adjust the margin as needed
                }}
              />

              <DataTable columns={columns} data={filteredData} pagination />
            </div>

            {/* <YourComponent/> */}
          </div>
        </div>
      </div>


      

    </div>
  );
}
