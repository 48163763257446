import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { MdKeyboardArrowRight } from "../../assets/icons/vander";
import { carsApi } from "../../api";
import DataTable from "react-data-table-component";
import { format } from "date-fns";
import {
  InfoIcon,
  CheckCircleIcon,
  WarningTwoIcon,
  ArrowLeftIcon,
  ArrowRightIcon,
} from "@chakra-ui/icons";

//import Modal from "react-modal";
import {
  Heading,
  useToast,
  Button,
  Flex,
  ModalBody,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalCloseButton,
  Box,
  //   Image,
  Text,
} from "@chakra-ui/react";
import { set } from "date-fns";
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
  Image,
} from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";

export default function PostAprove() {
  const [rq, setRq] = useState([]);
  const toast = useToast();
  const [errorMessage, setErrorMessage] = useState("");
  const [searchText, setSearchText] = useState("");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [filteredData, setFilteredData] = useState(null);

  const columns = [
    {
      name: "Vehicle ID",
      selector: "vehicle_id",
      sortable: true,
      cell: (row) => row.vehicle_id.toString(),
    },
    // {
    //   name: "brand_list",
    //   selector: "vehicle_brand_list",
    //   sortable: true,
    //   cell: (row) => {
    //     return `${row.vehicle_brand_list.vehicle_brand_name_eng}`;
    //   },
    // },
    // {
    //   name: "model_trim_list",
    //   selector: "vehicle_model_trim_list",
    //   sortable: true,
    //   cell: (row) => {
    //     return `${row.vehicle_model_trim_list.vehicle_trim_name}`;
    //   },
    // },
    // { name: "year", selector: "vehicle_year_model", sortable: true },
    {
      name: "Request Date",
      selector: "createdAt",
      sortable: true,
      width: "140px",
      cell: (row) => format(new Date(row.createdAt), "yyyy-MM-dd HH:mm:ss"),
    },
    {
      name: "brand_list",
      selector: "vehicle_brand_list",
      sortable: true,
      width: "100px",
      cell: (row) => {
        return `${row.vehicle_brand_list.vehicle_brand_name_eng}`;
      },
    },
    {
      name: "model_trim_list",
      selector: "vehicle_model_trim_list",
      sortable: true,
      width: "120px",
      cell: (row) => {
        return `${row.vehicle_model_trim_list.vehicle_trim_name}`;
      },
    },
    {
      name: "Is Approved",
      selector: "is_approved",
      sortable: true,
      width: "100px",
      cell: (row) => (
        <Box
          backgroundColor={"#FFD966"}
          color="grey"
          padding="8px"
          borderRadius="4px"
        >
          {row.is_approved}
        </Box>
      ),
    },
    // {
    //   name: "condition",
    //   selector: "vehicle_condition",
    //   sortable: true,
    //   cell: (row) => {
    //     return `${row.vehicle_condition.vehicle_condition_name_eng}`;
    //   },
    // },

    {
      name: "Actions",
      width: "150px",
      cell: (row) => (
        <div>
          <Flex justifyContent="space-around" alignItems="center">
            {/* <Button
            //   mt={1}
             size='xs'
              colorScheme="blue"
              type="submit"
              marginRight={2}
              onClick={() => openModal(row.vehicle_id)}
            >
              Details
            </Button> */}

            <InfoIcon
              w={5}
              h={5}
              color="blue.500"
              marginRight={4}
              onClick={() => openModal(row.vehicle_id)}
              cursor="pointer"
            />
            {/* <Button  colorScheme="green" type="submit" marginRight={2} size="xs" onClick={() => approvecar(row.vehicle_id)}>
              Approve
            </Button> */}
            <CheckCircleIcon
              w={5}
              h={5}
              color="green.500"
              marginRight={4}
              onClick={() => approvecar(row.vehicle_id)}
              cursor="pointer"
            />
            {/* <Button  colorScheme="orange" type="submit" size="xs">
              Reject
            </Button> */}
            <WarningTwoIcon
              w={5}
              h={5}
              color="red.500"
              cursor="pointer"
              onClick={() => {
                rejectcar(row.store_id, row.vehicle_id);
              }}
            />
          </Flex>
        </div>
      ),
    },
  ];

  const filteredDataResult = rq.filter((item) =>
    Object.values(item).some(
      (value) =>
        value !== null &&
        value.toString().toLowerCase().includes(searchText.toLowerCase())
    )
  );

  useEffect(() => {
    document.documentElement.setAttribute("dir", "ltr");
    document.documentElement.classList.add("light");
    document.documentElement.classList.remove("dark");
  }, []);

  const ToastPromise = (title, message, request) => {
    toast.promise(request, {
      success: {
        title: title,
        description: message,
        position: "top-right",
        duration: 9000,
        isClosable: true,
      },
      error: {
        title: "ERROR",
        description: errorMessage
          ? errorMessage
          : "An issue occured with your request",
        position: "top-right",
        duration: 9000,
        isClosable: true,
      },
      loading: {
        title: "Please wait",
        description: "Processing...",
        position: "top-right",
        duration: 9000,
        isClosable: true,
      },
    });
  };

  useEffect(() => {
    const myPromise = new Promise((resolve, reject) => {
      carsApi
        .AllVehiclePending()
        .then((res) => {
          // console.log(res.data);
          setRq(res.data);
          resolve();
        })
        .catch((err) => {
          // console.log(err?.response);
          setErrorMessage("An issue ocurred getting the coupons");
          reject();
        });
    });
    ToastPromise("Get", `All cars was fetched`, myPromise);
  }, []);

  function allcars() {
    const myPromise = new Promise((resolve, reject) => {
      carsApi
        .AllVehiclePending()
        .then((res) => {
          // console.log(res.data);
          setRq(res.data);
          resolve();
        })
        .catch((err) => {
          // console.log(err?.response);
          setErrorMessage("An issue ocurred getting the coupons");
          reject();
        });
    });
    ToastPromise("Get", `All cars was fetched`, myPromise);
  }

  const approvecar = (id) => {
    // console.log(id)
    const myPromise = new Promise((resolve, reject) => {
      carsApi
        .AproveVehiclePost({ vehicle_id: id })
        .then((res) => {
          resolve();
          setTimeout(() => {
            closeModal();
          }, 1600);
          allcars();
        })
        .catch((err) => {
          // console.log(err?.response)
          setErrorMessage("An issue ocurred getting the coupons");
          reject();
        });
    });
    ToastPromise("approved", `vehicle post approved`, myPromise);
  };

  const rejectcar = (store_id, vehicle_id) => {
    //console.log(id)
    const myPromise = new Promise((resolve, reject) => {
      carsApi
        .RejectVehiclePostRefund({ store_id: store_id, vehicle_id: vehicle_id })
        .then((res) => {
          resolve();
          setTimeout(() => {
            closeModal();
          }, 1600);
          allcars();
          // console.log(res.data)
        })
        .catch((err) => {
          if (err.response.data.message === "it is not ind") {
            setErrorMessage("Not Individual");
          } else {
            setErrorMessage("An issue occurred getting the coupons");
          }
          reject();
        });
    });
    ToastPromise("Rejected", `post reject and customer refunded`, myPromise);
  };

  const openModal = (vehicleId) => {
    // Filter the data based on the selected vehicle_id
    const filteredData = rq.find((item) => item.vehicle_id === vehicleId);
    setFilteredData(filteredData);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setFilteredData(null);
    setModalIsOpen(false);
  };

  return (
    <div className="container-fluid relative px-3">
      <div className="layout-specing">
        <div className="md:flex justify-between items-center">
          <h5 className="text-lg font-semibold">All pending cars</h5>

          <ul className="tracking-[0.5px] inline-flex items-center sm:mt-0 mt-3">
            <li className="inline-block capitalize text-[14px] font-bold duration-500 dark:text-white/70 hover:text-indigo-600 dark:hover:text-white">
              <Link to="/">Home</Link>
            </li>
            <li className="inline-block text-base text-slate-950 dark:text-white/70 mx-0.5 ltr:rotate-0 rtl:rotate-180">
              <MdKeyboardArrowRight />
            </li>
            <li
              className="inline-block capitalize text-[14px] font-bold text-indigo-600 dark:text-white"
              aria-current="page"
            >
              All pending cars
            </li>
          </ul>
        </div>

        <div className="grid grid-cols-1 mt-6">
          <div className="rounded-md shadow dark:shadow-gray-700 p-6 bg-white dark:bg-slate-900">
            <span className="flex-inline">
              {/* <Heading as='h3' size='md' paddingBottom={5}></Heading> */}
            </span>

            <div>
              <input
                type="text"
                placeholder="Search..."
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                style={{
                  padding: "4px",
                  borderRadius: "5px",
                  border: "1px solid #ccc",
                  marginRight: "10px", // Adjust the margin as needed
                }}
              />

              <DataTable
                columns={columns}
                data={filteredDataResult}
                pagination
              />
            </div>
          </div>
        </div>
      </div>

      {filteredData && (
        <Modal isOpen={openModal} onClose={closeModal} size="xl" isCentered>
          <ModalOverlay />
          <ModalContent maxW="900px">
            <ModalHeader>
              Vehicle Details
              {/* <Flex align="center" justify="center">
            <Button mt={1} colorScheme="green" marginRight={5} marginBottom={1} size={'sm'} onClick={() => approvecar(filteredData.vehicle_id)}>
              Approve
            </Button>
            <Button mt={1} colorScheme="orange" marginRight={1} marginBottom={1} size={'sm'}>
              Reject
            </Button>
           </Flex> */}
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Flex>
                <Flex flexDir="column" align="center" justify="center" w="50%">
                  {/* {console.log(`https://api.sure2shops.com/${filteredData?.vehicle_images_lists[0]?.image_url}`)} */}
                  {filteredData &&
                    filteredData?.vehicle_images_lists.length > 0 && (
                      <CarouselProvider
                        naturalSlideWidth={600}
                        naturalSlideHeight={600}
                        totalSlides={
                          filteredData?.vehicle_images_lists.length || 0
                        }
                        style={{ width: "100%" }}
                      >
                        <Slider>
                          {filteredData?.vehicle_images_lists.map(
                            (image, index) => {
                              return (
                                <Slide index={index}>
                                  <Image
                                    src={`http://172.20.10.2:8080/${image?.image_url}`}
                                    alt="Vehicle Image"
                                  />
                                </Slide>
                              );
                            }
                          )}
                        </Slider>
                        <Flex align="center" justify="center">
                          <ButtonBack>
                            {/* <Button mt={1} colorScheme="blue" marginRight={5}>
              Back
            </Button> */}
                            <ArrowLeftIcon
                              w={8}
                              h={8}
                              marginTop={4}
                              marginRight={10}
                              color="grey.200"
                              cursor="pointer"
                            />
                          </ButtonBack>
                          <ButtonNext>
                            {/* <Button mt={1} colorScheme="blue" marginRight={5}>
              Next
            </Button> */}

                            <ArrowRightIcon
                              w={8}
                              h={8}
                              marginTop={4}
                              marginLeft={10}
                              color="grey.200"
                              cursor="pointer"
                            />
                          </ButtonNext>
                        </Flex>
                      </CarouselProvider>
                    )}
                </Flex>

                {/* Side 2: Details */}

                <Flex
                  flexDir="column"
                  w="50%"
                  p={4}
                  border="1px solid #ccc"
                  borderRadius="md"
                >
                  <Flex justifyContent="right" alignItems="right">
                    <CheckCircleIcon
                      w={6}
                      h={6}
                      marginBottom={1}
                      color="green.500"
                      marginRight={4}
                      onClick={() => approvecar(filteredData.vehicle_id)}
                      cursor="pointer"
                    />
                    {/* <WarningTwoIcon w={6} h={6} marginBottom={1} color="red.500"  cursor="pointer" onClick={()=>rejectcar(filteredData.store_id)} /> */}
                    <WarningTwoIcon
                      w={6}
                      h={6}
                      marginBottom={1}
                      color="red.500"
                      cursor="pointer"
                      onClick={() =>
                        rejectcar(
                          filteredData.store_id,
                          filteredData.vehicle_id
                        )
                      }
                    />
                  </Flex>
                  <Text fontSize="md" mb={1}>
                    Details for Vehicle ID: <br></br>
                    {filteredData.vehicle_id}
                  </Text>

                  <Flex justifyContent="space-around">
                    <Box mt={2}>
                      <Text fontSize="md" fontWeight="bold">
                        Vehicle Brand:
                      </Text>
                      <Text>
                        {filteredData.vehicle_brand_list.vehicle_brand_name_eng}
                      </Text>
                    </Box>
                    <Box mt={2}>
                      <Text fontSize="md" fontWeight="bold">
                        Vehicle Model:
                      </Text>
                      <Text>
                        {
                          filteredData.vehicle_brand_model_list
                            .vehicle_model_name_eng
                        }
                      </Text>
                    </Box>
                  </Flex>

                  <Flex justifyContent="space-around">
                    <Box>
                      <Text fontSize="md" fontWeight="bold">
                        Vehicle Trim:
                      </Text>
                      <Text>
                        {filteredData.vehicle_model_trim_list.vehicle_trim_name}
                      </Text>
                    </Box>
                    <Box>
                      <Text fontSize="md" fontWeight="bold">
                        Year Model:
                      </Text>
                      <Text>{filteredData.vehicle_year_model}</Text>
                    </Box>
                  </Flex>
                  <Flex justifyContent="space-around">
                    <Box mt={2}>
                      <Text fontSize="md" fontWeight="bold">
                        Millage_driven:
                      </Text>
                      <Text>{filteredData.vehicle_millage_driven}</Text>
                    </Box>

                    <Box mt={2}>
                      <Text fontSize="md" fontWeight="bold">
                        Selling Price:
                      </Text>
                      <Text>{filteredData.vehicle_selling_price}</Text>
                    </Box>
                  </Flex>

                  <Flex justifyContent="space-around">
                    <Box mt={2}>
                      <Text fontSize="md" fontWeight="bold">
                        Vehicle color:
                      </Text>
                      <Text>{filteredData.color_table.color_en}</Text>
                    </Box>

                    <Box mt={2}>
                      <Text fontSize="md" fontWeight="bold">
                        Paint parts:
                      </Text>
                      <Text>{filteredData.vehicle_paint_parts}</Text>
                    </Box>
                  </Flex>

                  <Flex justifyContent="space-around">
                    <Box mt={2}>
                      <Text fontSize="md" fontWeight="bold">
                        Vehicle transmission:
                      </Text>
                      <Text>
                        {
                          filteredData.vehicle_transmission_type
                            .vehicle_transmission_type_name_eng
                        }
                      </Text>
                    </Box>

                    <Box mt={2}>
                      <Text fontSize="md" fontWeight="bold">
                        Vehicle drivetrain:
                      </Text>
                      <Text>
                        {
                          filteredData.vehicle_Drivetrain_type
                            .drivetrain_type_eng
                        }
                      </Text>
                    </Box>
                  </Flex>

                  <Flex justifyContent="space-around">
                    <Box mt={2}>
                      <Text fontSize="md" fontWeight="bold">
                        Plate details:
                      </Text>
                      <Text>
                        {`
         ${filteredData.vehicle_plate_type.vehicle_plate_type_name_eng} -
         ${filteredData.vehicle_plate_city.vehicle_plate_city_name_eng}
        `}
                      </Text>
                    </Box>

                    <Box mt={2}>
                      <Text fontSize="md" fontWeight="bold">
                        Engine details:
                      </Text>
                      <Text>
                        {`
         ${filteredData.vehicle_cylinder.vehicle_cylinder_name} -
         ${filteredData.vehicle_engine_size.vehicle_engine_size_name_eng} -
         ${filteredData.vehicle_fuel_type.vehicle_fuel_type_name_eng}
        `}
                      </Text>
                    </Box>
                  </Flex>

                  <Flex justifyContent="space-around">
                    <Box mt={2}>
                      <Text fontSize="md" fontWeight="bold">
                        Interial details:
                      </Text>
                      <Text>
                        {`
         seat number ${filteredData.vehicle_seat_number} -
         ${filteredData.vehicle_seat_material.vehicle_seat_material_name_eng}
        `}
                      </Text>
                    </Box>

                    <Box mt={2}>
                      <Text fontSize="md" fontWeight="bold">
                        Import country:
                      </Text>
                      <Text>
                        {
                          filteredData.vehicle_import_country
                            .vehicle_import_country_name_eng
                        }
                      </Text>
                    </Box>
                  </Flex>
                </Flex>
              </Flex>
            </ModalBody>

            <ModalFooter></ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </div>
  );
}
