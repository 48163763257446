import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { MdKeyboardArrowRight } from "../../assets/icons/vander";
import { storeApi } from "../../api";
import DataTable from "react-data-table-component";
import { format } from "date-fns";
import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Heading,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuItemOption,
  MenuGroup,
  MenuOptionGroup,
  MenuDivider,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  useToast,
  Select,
  Text,
  Stack,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Input,
  Checkbox,
  CheckboxGroup,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Textarea,
  Box,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";

export default function Cc() {
  const [rq, setRq] = useState([]);
  const toast = useToast();
  const [errorMessage, setErrorMessage] = useState("");
  const [refreshData, setRefreshData] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [filteredData, setFilteredData] = useState(null);
  const [replyText, setReplyText] = useState("");

  const columns = [
    { name: "Email", selector: "customer_email", sortable: true },
    { name: "NAME", selector: "full_name", sortable: true },
    { name: "COMMENT", selector: "comment", sortable: true },
    { name: "T_RP", selector: "team_replied", sortable: true },
    {
      name: "DATE",
      selector: "receiving_date",
      sortable: true,
      cell: (row) =>
        format(new Date(row.receiving_date), "yyyy-MM-dd HH:mm:ss"),
    },
    {
      name: "Actions",
      cell: (row) => (
        <div>
          {/* <button style={{ marginRight: "10px", color: "green" }} onClick={() => approveRequest(row.id)}>Approve</button>
              <button style={{ color: "orange" }} onClick={() => rm(row.id)}>Reject</button> */}
          <button
            style={{ marginRight: "10px", color: "blue" }}
            onClick={() => openModal(row.id)}
            cursor="pointer"
          >
            Reply
          </button>
        </div>
      ),
    },
  ];

  //   const filteredData = rq.filter(item =>
  //     Object.values(item).some(value =>
  //       value !== null && value.toString().toLowerCase().includes(searchText.toLowerCase())
  //     )
  //   );

  useEffect(() => {
    document.documentElement.setAttribute("dir", "ltr");
    document.documentElement.classList.add("light");
    document.documentElement.classList.remove("dark");

    // adminApi.AllBalanceOffers()
    // .then(res=>{
    //     console.log(res.data)
    //     setBalanceOfferList(res.data)
    // })
    // .catch(err=>{
    //     console.log(err?.response)
    // })

    // adminApi.AllOffers()
    // .then(res=>{
    //     console.log(res.data)
    //     setOfferList(res.data)
    // })
    // .catch(err=>{
    //     console.log(err?.response)
    // })
  }, []);

  const ToastPromise = (title, message, request) => {
    toast.promise(request, {
      success: {
        title: title,
        description: message,
        position: "top-right",
        duration: 9000,
        isClosable: true,
      },
      error: {
        title: "ERROR",
        description: errorMessage
          ? errorMessage
          : "An issue occured with your request",
        position: "top-right",
        duration: 9000,
        isClosable: true,
      },
      loading: {
        title: "Please wait",
        description: "Processing...",
        position: "top-right",
        duration: 9000,
        isClosable: true,
      },
    });
  };

  const getData = () => {
    const myPromise = new Promise((resolve, reject) => {
      storeApi
        .AllCommnets()
        .then((res) => {
          // console.log(res.data)
          setRq(res.data);
          resolve();
        })
        .catch((err) => {
          // console.log(err?.response)
          setErrorMessage("An issue ocurred getting the comments");
          reject();
        });
    });
    ToastPromise("Get", `All comments was fetched`, myPromise);
  };
  const filteredDataResult = rq.filter((item) =>
    Object.values(item).some(
      (value) =>
        value !== null &&
        value.toString().toLowerCase().includes(searchText.toLowerCase())
    )
  );

  useEffect(() => {
    const myPromise = new Promise((resolve, reject) => {
      storeApi
        .AllCommnets()
        .then((res) => {
          // console.log(res.data)
          setRq(res.data);
          resolve();
        })
        .catch((err) => {
          // console.log(err?.response)
          setErrorMessage("An issue ocurred getting the coupons");
          reject();
        });
    });
    ToastPromise("Get", `All comments was fetched`, myPromise);
  }, [refreshData]);

  // const approveRequest=(id)=>{
  //     console.log(id)
  //     const myPromise = new Promise((resolve, reject) => {
  //     accountApi.addBalancePaymentTest({"rq_id":id})
  //     .then(res=>{
  //         console.log(res.message)
  //         accountApi.rmrequest({"id":id}).then(res=>{ getData()})
  //         resolve()
  //     })
  //     .catch(err=>{
  //         console.log(err?.response)
  //         setErrorMessage("An issue ocurred getting the coupons")
  //         reject()
  //     })
  //     })
  //     ToastPromise('approved',`balance added`,myPromise)
  // }

  // const rm=(id)=>{
  //     console.log(id)
  //   const myPromise = new Promise((resolve, reject) => {
  //     accountApi.rmrequest({"id":id})
  //     .then(res=>{
  //       console.log(res.message)
  //       getData()
  //       resolve()
  //   })
  //   .catch(err=>{
  //       console.log(err?.response)
  //       setErrorMessage("An issue ocurred getting the coupons")
  //       reject()
  //   })

  //   })
  //   ToastPromise('removed',`request removed`,myPromise)
  // }

  const openModal = (bbv) => {
    // Filter the data based on the selected vehicle_id
    const filteredData = rq.find((item) => item.id === bbv);
    setFilteredData(filteredData);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setFilteredData(null);
    setModalIsOpen(false);
  };

  const replyAc = (id, rplytext, email) => {
    // console.log(id)
    //console.log(id)
    const myPromise = new Promise((resolve, reject) => {
      storeApi
        .replyComent(
          JSON.stringify({ cid: id, message: rplytext, cEmail: email })
        )
        .then((res) => {
          resolve();
          setTimeout(() => {
            closeModal();
            setReplyText("");
          }, 1600);
          getData();
          // console.log(res.data)
        })
        .catch((err) => {
          // console.log(err)
          //   if (err.response.data.message === 'it is not ind') {
          //   setErrorMessage("Not Individual");
          // } else {
          //   setErrorMessage("An issue occurred getting the coupons");
          // }
          reject();
        });
    });
    ToastPromise("Replyed", `${email} got the email`, myPromise);
  };

  return (
    <div className="container-fluid relative px-3">
      <div className="layout-specing">
        <div className="md:flex justify-between items-center">
          <h5 className="text-lg font-semibold">Customer comments</h5>

          <ul className="tracking-[0.5px] inline-flex items-center sm:mt-0 mt-3">
            <li className="inline-block capitalize text-[14px] font-bold duration-500 dark:text-white/70 hover:text-indigo-600 dark:hover:text-white">
              <Link to="/">Home</Link>
            </li>
            <li className="inline-block text-base text-slate-950 dark:text-white/70 mx-0.5 ltr:rotate-0 rtl:rotate-180">
              <MdKeyboardArrowRight />
            </li>
            <li
              className="inline-block capitalize text-[14px] font-bold text-indigo-600 dark:text-white"
              aria-current="page"
            >
              Customer comments
            </li>
          </ul>
        </div>

        <div className="grid grid-cols-1 mt-6">
          <div className="rounded-md shadow dark:shadow-gray-700 p-6 bg-white dark:bg-slate-900">
            <span className="flex-inline">
              <Heading as="h3" size="md" paddingBottom={5}>
                All Customer comments
              </Heading>
            </span>

            <div>
              <input
                type="text"
                placeholder="Search..."
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                style={{
                  padding: "4px",
                  borderRadius: "5px",
                  border: "1px solid #ccc",
                  marginRight: "10px", // Adjust the margin as needed
                }}
              />

              <DataTable
                columns={columns}
                data={filteredDataResult}
                pagination
              />
            </div>

            {/* <YourComponent/> */}
          </div>
        </div>
      </div>

      {filteredData && (
        <Modal isOpen={openModal} onClose={closeModal} size="xl" isCentered>
          <ModalOverlay />
          <ModalContent maxW="400px">
            {/* {console.log(filteredData.customer_email)} */}
            <ModalHeader>
              {/* <Flex align="center" justify="center">
            <Button mt={1} colorScheme="green" marginRight={5} marginBottom={1} size={'sm'} onClick={() => approvecar(filteredData.vehicle_id)}>
              Approve
            </Button>
            <Button mt={1} colorScheme="orange" marginRight={1} marginBottom={1} size={'sm'}>
              Reject
            </Button>
           </Flex> */}
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Box>
                <Heading size="md" mb={4}>
                  Reply to {filteredData.customer_email}
                </Heading>
                <Textarea
                  value={replyText}
                  onChange={(e) => setReplyText(e.target.value)}
                  placeholder="Type your reply here..."
                  mb={4}
                />
                {/* <Button colorScheme="teal" onClick={()=>{replyAc(filteredData.id.toString,replyText,filteredData.customer_email)}}>
        Send Reply
      </Button> */}
                <Button
                  colorScheme="teal"
                  onClick={() =>
                    replyAc(
                      filteredData.id.toString(),
                      replyText,
                      filteredData.customer_email
                    )
                  }
                >
                  Send Reply
                </Button>
              </Box>
            </ModalBody>

            <ModalFooter></ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </div>
  );
}
