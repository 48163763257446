import React,{useState, useEffect} from "react";
import { Link } from "react-router-dom";

import * as Icon from 'react-feather'
import {AiOutlineCamera,BsHeartbreak} from '../../../assets/icons/vander'

import backgroundImage from '../../../assets/images/blog/bg.jpg'
import circleLogo from '../../../assets/images/client/circle-logo.png'
import facebookLogo from '../../../assets/images/client/facebook-logo-2019.png'
import spotifyLogo from '../../../assets/images/client/spotify.png'
import portfolio1 from '../../../assets/images/portfolio/1.jpg'
import portfolio2 from '../../../assets/images/portfolio/2.jpg'
import portfolio3 from '../../../assets/images/portfolio/3.jpg'
import portfolio4 from '../../../assets/images/portfolio/4.jpg'
import portfolio5 from '../../../assets/images/portfolio/5.jpg'
import portfolio6 from '../../../assets/images/portfolio/6.jpg'

import UserProfileTab from "./userProfileTab";

import Lightbox from 'react-18-image-lightbox';
import "react-18-image-lightbox/style.css"
import {storeApi,common, adminApi,couponApi} from '../../../api/index'
import default_store_img from '../../../assets/images/default_profile_black_v3.png';
import default_back_img from '../../../assets/images/default_back_img.png';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { Select, Text, Stack, Heading, FormControl, FormLabel, FormErrorMessage, FormHelperText,
    Button, Input, useToast } from '@chakra-ui/react'

const successInit = {
    balanceAmount: true,
    coupon: true
    };

export default function AddBalance({onRefreshData}){
    const navigate = useNavigate();
    const [storeInfo, setStoreInfo] = useState({});
    const { status,id } = useParams();
    const [balanceAmount,setBalanceAmount] = useState('');
    const [balanceOfferList,setBalanceOfferList] = useState([]);
    const [coupon,setCoupon] = useState('');
    const [success, setSuccess] = useState(successInit);
    const toast = useToast()
    const [loading, setLoading] = useState(false);
    const [accountId, setAccountId] = useState('');
    const [errorMessage, setErrorMessage] = useState("");

    useEffect(() => {
        document.documentElement.setAttribute("dir", "ltr");
        document.documentElement.classList.add('light');
        document.documentElement.classList.remove('dark');        

        if(status === "pending"){
            //search in temp store_list table
            storeApi.singleTempInfo({store_id:id})
            .then(res=>{
                console.log(res.data)
                setStoreInfo(res.data)
            })
            .catch(err=>{
                console.log(err?.response?.message)
            })
        }
        else{
            //search in store_list table
            storeApi.singleStoreInfo({store_id:id})
            .then(res=>{
                console.log(res.data)
                setStoreInfo(res.data)
            })
            .catch(err=>{
                console.log(err?.response?.message)
            })
        }

        storeApi.getUserStoreByStoreId({store_id:id})
        .then(res=>{
            console.log(res.data.store_account_id)
            setAccountId(res.data.store_account_id)
        })
        .catch(err=>{
            console.log(err)
        })

        adminApi.AllBalanceOffers()
        .then(res=>{
            console.log(res.data)
            setBalanceOfferList(res.data)
        })
        .catch(err=>{
            console.log(err?.response)
        })
    }, []);

    const ToastPromise=(title,message,request)=>{
        toast.promise(request, {
            success: { title: title, description: message, position:'top-right', duration: 9000,isClosable: true},
            error: { title: 'ERROR', description: ((errorMessage)?errorMessage:'An issue occured with your request'), position:'top-right', duration: 9000,isClosable: true },
            loading: { title: 'Please wait', description: 'Processing...', position:'top-right', duration: 9000,isClosable: true },
          })
    }
    
    const addBalance=()=>{
        setSuccess({
            ...success,
            balanceAmount: true
          });
        if (balanceAmount === '') {
            console.log()
          setSuccess({
            ...success,
            balanceAmount: balanceAmount !== '' ? true : false,
          });
        } 
        else{
            
            let data ={
                store_account_id:accountId,
                offer_id:balanceAmount
            }
            console.log(data)
        const myPromise = new Promise((resolve, reject) => {
            adminApi.AddBalance(data)
            .then(res=>{
                resolve(res.data)
                console.log(res.data,"success")
            })
            .catch(err=>{
                console.log(err?.response?.message,"error")
                reject(err)
            })
            })
        ToastPromise('Added',`Balance was added to ${storeInfo.store_name_eng}`,myPromise)
    }
}
    
const addCoupon=()=>{
    setSuccess({
        ...success,
        coupon: true
      });
    if (coupon === '') {
        console.log()
      setSuccess({
        ...success,
        coupon: coupon !== '' ? true : false,
      });
    } 
    else{
        
        let data ={
            store_account_id:accountId,
            coupon_id:coupon
        }
        console.log(data)
    const myPromise = new Promise((resolve, reject) => {
        couponApi.AddRefiilInd(data)
        .then(res=> {
            resolve(res.data)
         // navigation.navigate('StoreApproval',{id:item.store_id})
        })
       //  catch for store_list error
        .catch(err=>{
         if(err.response.data.message === "coupon is not valid !"){
            setErrorMessage(err.response.data.message)
            reject('coupon is not valid !')
         }
         else{
            setErrorMessage("An issue occurred with your request, please try again.")
            reject('An issue occurred with your request, please try again.')
         }
       })
    })
    ToastPromise('Coupon Added',`Coupon was activated to ${storeInfo.store_name_eng}`,myPromise)
}
}

    return(
        <>
            <div className="xl:col-span-9 lg:col-span-8 md:col-span-8 mt-6">
                <div className="grid grid-cols-1 gap-6">
                    <div className="grid lg:grid-cols-2 grid-cols-2 gap-6">
                        <div className="p-6 relative rounded-md shadow dark:shadow-gray-700 bg-white dark:bg-slate-900 h-fit">
                            <div className="mt-6 grid lg:grid-cols-1 grid-cols-1">
                                    <Stack spacing={3}>
                                        <Heading as='h2' size='md'>Add Balance</Heading>
                                        <Text fontSize='md'>You can add balance through below form or using a coupon for the customer.</Text>
                                        <FormLabel>Select amount</FormLabel>
                                        <FormControl isInvalid={!success.balanceAmount}>
                                            <Select placeholder='Select balance amount' onChange={(e)=>setBalanceAmount(e.target.value)}>
                                                {balanceOfferList.map((offer,index)=>{
                                                    return (<option key={offer.balance_offer_id} value={offer.balance_offer_id}>{offer.balance_offer_name}</option>)
                                                })}
                                            </Select>
                                            <FormErrorMessage>Balance amount is required</FormErrorMessage>
                                        </FormControl>
                                        
                                        <Button
                                            mt={4}
                                            colorScheme='teal'
                                            // isLoading={props.isSubmitting}
                                            type='submit'
                                            onClick={()=>addBalance()}
                                        >
                                            Add Balance
                                        </Button>
                                    </Stack>
                            </div>
                        </div>

                        <div className="p-6 relative rounded-md shadow dark:shadow-gray-700 bg-white dark:bg-slate-900 h-fit">
                            <div className="mt-6 grid lg:grid-cols-1 grid-cols-1">
                                    <Stack spacing={3}>
                                        <Heading as='h2' size='md'>Add Balance Coupon</Heading>
                                        <FormLabel>Enter coupon code</FormLabel>
                                        <FormControl isInvalid={!success.coupon}>
                                            <Input placeholder='Enter Coupon' onChange={(e)=>setCoupon(e.target.value)}/>
                                            <FormErrorMessage>Coupon is required</FormErrorMessage>
                                        </FormControl>
                                        
                                        <Button
                                            mt={4}
                                            colorScheme='teal'
                                            // isLoading={props.isSubmitting}
                                            type='submit'
                                            onClick={()=>addCoupon()}
                                        >
                                            Add Coupon
                                        </Button>
                                    </Stack>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}