import { combineReducers } from 'redux';
import AuthReducer from './auth';
import ApplicationReducer from './application';
import storeAuthReducer from './storeAuth';
import adminReducer from './admin';

export default combineReducers({
  auth: AuthReducer,
  application: ApplicationReducer,
  storeAuth:storeAuthReducer,
  admin:adminReducer
});
