import http from "../http-common";

  export const checkBalance = (data) => {
    return http.post(`/couponAction/CheckBalance`,data);
  }

  export const checkOffer = (data) => {
    return http.post(`/couponAction/CheckOffer`,data);
  }

  export const AddRefiilInd = (data) => {
    return http.post(`/couponAction/AddRefiilInd`,data);
  }
  
  export const AddOffer = (data) => {
    return http.post(`/couponAction/AddOffer`,data);
  }
  
  export const AllCoupons = () => {
    return http.get(`/couponAction/AllCoupons`);
  }
  
  export const NewCouponForOffer = (data) => {
    return http.post(`/couponAction/newCouponForOffer`,data);
  }

  export const NewCouponForRefill = (data) => {
    return http.post(`/couponAction/newCouponForRefill`,data);
  }