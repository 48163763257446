import Blog1 from '../assets/images/blog/01.jpg'
import Blog2 from '../assets/images/blog/02.jpg'
import Blog3 from '../assets/images/blog/03.jpg'
import Blog4 from '../assets/images/blog/04.jpg'
import Blog5 from '../assets/images/blog/05.jpg'
import Blog6 from '../assets/images/blog/06.jpg'
import Blog7 from '../assets/images/blog/07.jpg'
import Blog8 from '../assets/images/blog/08.jpg'

import product1 from '../assets/images/shop/items/s1.jpg'
import product2 from '../assets/images/shop/items/s2.jpg'
import product3 from '../assets/images/shop/items/s3.jpg'
import product4 from '../assets/images/shop/items/s4.jpg'
import product5 from '../assets/images/shop/items/s5.jpg'
import product6 from '../assets/images/shop/items/s6.jpg'
import product7 from '../assets/images/shop/items/s7.jpg'
import product8 from '../assets/images/shop/items/s8.jpg'
import product9 from '../assets/images/shop/items/s9.jpg'
import product10 from '../assets/images/shop/items/s10.jpg'

import portfolio1 from '../assets/images/portfolio/1.jpg'
import portfolio2 from '../assets/images/portfolio/2.jpg'
import portfolio3 from '../assets/images/portfolio/3.jpg'
import portfolio4 from '../assets/images/portfolio/4.jpg'
import portfolio5 from '../assets/images/portfolio/5.jpg'
import portfolio6 from '../assets/images/portfolio/6.jpg'
import portfolio7 from '../assets/images/portfolio/7.jpg'
import portfolio8 from '../assets/images/portfolio/8.jpg'
import portfolio9 from '../assets/images/portfolio/9.jpg'
import portfolio10 from '../assets/images/portfolio/20.jpg'
import portfolio11 from '../assets/images/portfolio/21.jpg'
import portfolio12 from '../assets/images/portfolio/22.jpg'
import portfolio13 from '../assets/images/portfolio/23.jpg'
import portfolio14 from '../assets/images/portfolio/7.jpg'
import portfolio15 from '../assets/images/portfolio/4.jpg'

export  const blogData = [
    {
        id:1,
        image:Blog1,
        title:'Design your apps in your own way',
        desc:'The phrasal sequence of the is now so that many campaign and benefit'
    },
    {
        id:2,
        image:Blog2,
        title:'Design your apps in your own way',
        desc:'The phrasal sequence of the is now so that many campaign and benefit'
    },
    {
        id:3,
        image:Blog3,
        title:'Design your apps in your own way',
        desc:'The phrasal sequence of the is now so that many campaign and benefit'
    },
    {
        id:4,
        image:Blog4,
        title:'Design your apps in your own way',
        desc:'The phrasal sequence of the is now so that many campaign and benefit'
    },
    {
        id:5,
        image:Blog5,
        title:'Design your apps in your own way',
        desc:'The phrasal sequence of the is now so that many campaign and benefit'
    },
    {
        id:6,
        image:Blog6,
        title:'Design your apps in your own way',
        desc:'The phrasal sequence of the is now so that many campaign and benefit'
    },
    {
        id:7,
        image:Blog7,
        title:'Design your apps in your own way',
        desc:'The phrasal sequence of the is now so that many campaign and benefit'
    },
    {
        id:8,
        image:Blog8,
        title:'Design your apps in your own way',
        desc:'The phrasal sequence of the is now so that many campaign and benefit'
    },
]
export const productData = [
    {
        id:1,
        image: product1,
        name:'Branded T-Shirt',
    },
    {
        id:2,
        image: product2,
        name:'Shopping Bag',
    },
    {
        id:3,
        image: product3,
        name:'Elegent Watch',
    },
    {
        id:4,
        image: product4,
        name:'Casual Shoes',
    },
    {
        id:5,
        image: product5,
        name:'Earphones',
    },
    {
        id:6,
        image: product6,
        name:'Elegent Mug',
    },
    {
        id:7,
        image: product7,
        name:'Sony Headphones',
    },
    {
        id:8,
        image: product8,
        name:'Wooden Stools',
    },
    {
        id:9,
        image: product9,
        name:'Wooden Stools',
    },
    {
        id:10,
        image: product10,
        name:'Wooden Stools',
    },
]
export const galleryData = [
    {
        id:0,
        image:portfolio1,
        title:'Mockup Collection',
        subTitle:'Abstract',
        category:'branding'
    },
    {
        id:1,
        image:portfolio2,
        title:'Mockup Collection',
        subTitle:'Abstract',
        category:'designing'
    },
    {
        id:2,
        image:portfolio3,
        title:'Mockup Collection',
        subTitle:'Abstract',
        category:'photography'
    },
    {
        id:3,
        image:portfolio4,
        title:'Mockup Collection',
        subTitle:'Abstract',
        category:'development'
    },
    {
        id:4,
        image:portfolio5,
        title:'Mockup Collection',
        subTitle:'Abstract',
        category:'branding'
    },
    {
        id:5,
        image:portfolio6,
        title:'Mockup Collection',
        subTitle:'Abstract',
        category:'branding'
    },
    {
        id:6,
        image:portfolio7,
        title:'Mockup Collection',
        subTitle:'Abstract',
        category:'designing'
    },
    {
        id:7,
        image:portfolio8,
        title:'Mockup Collection',
        subTitle:'Abstract',
        category:'development'
    },
    {
        id:8,
        image:portfolio9,
        title:'Mockup Collection',
        subTitle:'Abstract',
        category:'photography'
    },
    {
        id:9,
        image:portfolio10,
        title:'Mockup Collection',
        subTitle:'Abstract',
        category:'photography'
    },
    {
        id:10,
        image:portfolio11,
        title:'Mockup Collection',
        subTitle:'Abstract',
        category:'designing'
    },
    {
        id:11,
        image:portfolio12,
        title:'Mockup Collection',
        subTitle:'Abstract',
        category:'photography'
    },
    {
        id:12,
        image:portfolio13,
        title:'Mockup Collection',
        subTitle:'Abstract',
        category:'development'
    },
    {
        id:13,
        image:portfolio14,
        title:'Mockup Collection',
        subTitle:'Abstract',
        category:'branding'
    },
    {
        id:14,
        image:portfolio15,
        title:'Mockup Collection',
        subTitle:'Abstract',
        category:'branding'
    },
]

export const images = [portfolio1,portfolio2, portfolio3, portfolio4, portfolio5, portfolio6, portfolio7, portfolio8, portfolio9, portfolio10, portfolio11, portfolio12, portfolio13, portfolio14, portfolio15]
