import * as actionTypes from '../actions/actionTypes';

const initialState = {
  adminProfile:{
    id:"",
    admin_uasername:"",
    admin_email:"",
    admin_phone:"",
    admin_rule:"",
    admin_status:"",
    createdAt:""
  },
  adminSession:{
    token_id:"",
    number:""
  },
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case actionTypes.SET_ADMIN_TOKEN:
      return {
        ...state,
        adminSession: action.data,
      };
    case actionTypes.SET_ADMIN_PROFILE:
      return {
        ...state,
        adminSession: action.data,
      };
    default:
      return state;
  }
};
