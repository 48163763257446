import axios from "axios";

// let http = axios.create({
//   baseURL: "https://wpdnkd.api.infobip.com/",
//   headers: {
//     "Accept": "application/json",
//     "Authorization":"1c9cb6a316c6ab8df720a918981f5126-a818845f-4d50-4c9b-a78c-88913c3b5494"
//   }
// });
  const options = {
    "url": "https://wpdnkd.api.infobip.com/2fa/2/applications",
    "method": "GET",
    "timeout": 0,
    "headers": {
        "Authorization": "App 1c9cb6a316c6ab8df720a918981f5126-a818845f-4d50-4c9b-a78c-88913c3b5494",
        "Accept": "application/json"
    },
  };
  
  const create2FAOptions = {
    "url": "https://wpdnkd.api.infobip.com/2fa/2/applications",
    "method": "POST",
    "timeout": 0,
    "headers": {
        "Authorization": "App 1c9cb6a316c6ab8df720a918981f5126-a818845f-4d50-4c9b-a78c-88913c3b5494",
        "Content-Type": "application/json",
        "Accept": "application/json"
    },
    "data": JSON.stringify({
        "name": "2fa sure2shops SMS",
        "enabled": true,
        "configuration": {
            "pinAttempts": 5,
            "allowMultiplePinVerifications": true,
            "pinTimeToLive": "10m",
            "verifyPinLimit": "2/4s",
            "sendPinPerApplicationLimit": "5000/12h",
            "sendPinPerPhoneNumberLimit": "30/1d"
        }
    }),
  };

  const update2FAOptions = {
    "url": "https://wpdnkd.api.infobip.com/2fa/2/applications/FA9F5C7D6B6638A2773C109DB3FCB635",
    "method": "PUT",
    "timeout": 0,
    "headers": {
        "Authorization": "App 1c9cb6a316c6ab8df720a918981f5126-a818845f-4d50-4c9b-a78c-88913c3b5494",
        "Content-Type": "application/json",
        "Accept": "application/json"
    },
    "data": JSON.stringify({
        "name": "2fa sure2shops SMS",
        "enabled": true,
        "configuration": {
            "pinAttempts": 5,
            "allowMultiplePinVerifications": true,
            "pinTimeToLive": "10m",
            "verifyPinLimit": "2/4s",
            "sendPinPerApplicationLimit": "5000/12h",
            "sendPinPerPhoneNumberLimit": "30/1d"
        }
    }),
  };
  
  const getSingle2FAOptions = {
    "url": "https://wpdnkd.api.infobip.com/2fa/2/applications/FA9F5C7D6B6638A2773C109DB3FCB635",
    "method": "GET",
    "timeout": 0,
    "headers": {
        "Authorization": "App 1c9cb6a316c6ab8df720a918981f5126-a818845f-4d50-4c9b-a78c-88913c3b5494",
        "Accept": "application/json"
    },
  };
  
  
  const message_2FA_templates_options = {
    "url": "https://wpdnkd.api.infobip.com/2fa/2/applications/FA9F5C7D6B6638A2773C109DB3FCB635/messages",
    "method": "GET",
    "timeout": 0,
    "headers": {
        "Authorization": "App 1c9cb6a316c6ab8df720a918981f5126-a818845f-4d50-4c9b-a78c-88913c3b5494",
        "Accept": "application/json"
    },
  };
  
  const create_2FA_template_options = {
    "url": "https://wpdnkd.api.infobip.com/2fa/2/applications/FA9F5C7D6B6638A2773C109DB3FCB635/messages",
    "method": "POST",
    "timeout": 0,
    "headers": {
        "Authorization": "App 1c9cb6a316c6ab8df720a918981f5126-a818845f-4d50-4c9b-a78c-88913c3b5494",
        "Content-Type": "application/json",
        "Accept": "application/json"
    },
    "data": JSON.stringify({
        "pinType": "NUMERIC",
        "messageText": "Welcome to Sure To Shops! Please use this code for verifying your mobile number: {{pin}}",
        "pinLength": 6,
        "language": "en",
        "senderId": "SureToShops",
        "repeatDTMF": "1#",
        "speechRate": 1
      }),
  };
  //messageId en : 8BF1E615EB7C01EAC4D224B0B15DB012
  //2fa id:  FA9F5C7D6B6638A2773C109DB3FCB635
  const send_otp_options =(data)=> {
    return {
      "url": "https://wpdnkd.api.infobip.com/2fa/2/pin?ncNeeded=false",
      "method": "POST",
      "timeout": 0,
      "headers": {
          "Authorization": "App 1c9cb6a316c6ab8df720a918981f5126-a818845f-4d50-4c9b-a78c-88913c3b5494",
          "Content-Type": "application/json",
          "Accept": "application/json"
      },
      "data": JSON.stringify({
          "applicationId": "FA9F5C7D6B6638A2773C109DB3FCB635",
          "messageId": "8BF1E615EB7C01EAC4D224B0B15DB012",
          // "from": "447491163443",
          "to": `${data}`,
          // "placeholders": {
          //     "pin": "1234"
          // }
      })
    }
  };

const reSmsOTPOptions = (pin_id)=> {
  return {
    "url": `https://wpdnkd.api.infobip.com/2fa/2/pin/${pin_id}/resend`,
    "method": "POST",
    "timeout": 0,
    "headers": {
        "Authorization": "App 1c9cb6a316c6ab8df720a918981f5126-a818845f-4d50-4c9b-a78c-88913c3b5494",
        "Content-Type": "application/json",
        "Accept": "application/json"
    }
  }
}
  
const verifySmsOtpOptions = (pin_id,pin_code)=> {
  return {
    "url": `https://wpdnkd.api.infobip.com/2fa/2/pin/${pin_id}/verify`,
    "method": "POST",
    "timeout": 0,
    "headers": {
        "Authorization": "App 1c9cb6a316c6ab8df720a918981f5126-a818845f-4d50-4c9b-a78c-88913c3b5494",
        "Content-Type": "application/json",
        "Accept": "application/json"
    },
    "data": JSON.stringify({
        "pin": `${pin_code}`
    }),
  }
}
  
    export const get2FA = () => {
      return axios.request(options);
    }
    
    export const create2FA = () => {
      return axios.request(create2FAOptions);
    }

    export const update2FA = () => {
      return axios.request(update2FAOptions);
    }
  
    export const get2FASingle = () => {
      return axios.request(getSingle2FAOptions);
    }
  
    export const get2FAMessages = () => {
      return axios.request(message_2FA_templates_options);
    }
  
    export const create2FATemplate = () => {
      return axios.request(create_2FA_template_options);
    }
    
    export const sendSMSOtp = (data) => {
      return axios.request(send_otp_options(data));
    }

    export const reSendSmsOTP = (pin_id) => {
      return axios.request(reSmsOTPOptions(pin_id));
    }

    export const verifySmsOtp = (pin_id,pin_code) => {
      return axios.request(verifySmsOtpOptions(pin_id,pin_code));
    }

// WHATSAPP

const WH_2FA_template_options = {
  "url": "https://wpdnkd.api.infobip.com/whatsapp/2/senders/9647501699699/templates",
  "method": "POST",
  "timeout": 0,
  "headers": {
      "Authorization": "App 1c9cb6a316c6ab8df720a918981f5126-a818845f-4d50-4c9b-a78c-88913c3b5494",
      "Content-Type": "application/json",
      "Accept": "application/json"
  },
  "data": JSON.stringify({
      "name": "authentication_template_with_copy_code_button",
      "language": "en",
      "category": "AUTHENTICATION",
      "structure": {
          "body": {
              "addSecurityRecommendation": true
          },
          "footer": {
              "codeExpirationMinutes": 10
          },
          "buttons": [
              {
                  "text": "Copy code",
                  "otpType": "COPY_CODE"
              }
          ]
      }
  }),
};

//ahmed test number: 9647517091227

const send_WH_OTP_options = (number,otpCode) => {
  return {
    "url": "https://wpdnkd.api.infobip.com/whatsapp/1/message/template",
    "method": "POST",
    "timeout": 0,
    "headers": {
        "Authorization": "App 1c9cb6a316c6ab8df720a918981f5126-a818845f-4d50-4c9b-a78c-88913c3b5494",
        "Content-Type": "application/json",
        "Accept": "application/json"
    },
    "data": JSON.stringify({
        "messages": [
            {
                "from": "9647501699699",
                "to": `${number}`,
                // "messageId": "237887752567234",
                "content": {
                    "templateName": "authentication_template_with_copy_code_button",
                    "templateData": {
                        "body": {
                            "placeholders": [
                              `${otpCode}`
                            ]
                        },
                        "buttons": [
                            {
                                "type": "URL",
                                "parameter": `${otpCode}`
                            }
                        ]
                    },
                    "language": "en"
                },
                "callbackData": "Callback data",
                "smsFailover":{
                  "from": "SureToShops",
                  "to": `${number}`,
                  "text":`${otpCode} is your verification code. For your security, do not share this code.`
                }
                // "notifyUrl": "https://www.example.com/whatsapp",
                // "urlOptions": {
                //     "shortenUrl": true,
                //     "trackClicks": true,
                //     // "trackingUrl": "https://example.com/click-report",
                //     "removeProtocol": true,
                //     "customDomain": "example.com"
                // }
            }
        ]
    }),
  };
}

export const createWH2FA = () => {
  return axios.request(WH_2FA_template_options);
}

export const sendWH2FA = (number,otpCode) => {
  return axios.request(send_WH_OTP_options(number,otpCode));
}