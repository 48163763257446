import * as actionTypes from '../actions/actionTypes';

const initialState = {
  login: {
    success: true,
  },
  user: {
    lang: 'en',
  },
  signup:{
    firstName:"",
    lastName:"",
    city:"",
    PNumber:"",
    verified:false
  },
  userProfile:{
    first_name:"",
    last_name:"",
    customer_account_email:"",
    customer_account_phone_no:"",
    account_type_id:"",
    age:"",
    gender:"",
    city_id:"",
    customer_account_status:""
  },
  userSession:{
    token_id:"",
    customer_id:""
  },
  admin:{
    isAdmin:false
  },
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case actionTypes.LOGIN:
      return {
        ...state,
        login: action.data,
      };
    case actionTypes.SIGNUP:
      return {
        ...state,
        signup: action.data,
      };
    case actionTypes.USERSESSION:
      return {
        ...state,
        userSession: action.data,
      };
    case actionTypes.USERPROFILE:
      return {
        ...state,
        userProfile: action.data,
      };
    case actionTypes.SET_ADMIN_STATUS:
      return {
        ...state,
        admin: action.data,
      };
    default:
      return state;
  }
};
