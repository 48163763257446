// import AsyncStorage from '@react-native-async-storage/async-storage';
import { createStore, applyMiddleware } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import thunk from 'redux-thunk';
// import logger from 'redux-logger';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import rootReducer from '../reducers';
import storage from 'redux-persist/lib/storage'
/**
 * Redux Setting
 */
const persistConfig = {
  key: 'root',
  storage: storage,
  timeout: 86400,//1 day
  stateReconciler: autoMergeLevel2,
};

let middleware = [thunk];
// if (process.env.NODE_ENV === 'development') {
//   middleware.push(logger);
// }

const persistedReducer = persistReducer(persistConfig, rootReducer);
const store = createStore(persistedReducer, applyMiddleware(...middleware));
const persistor = persistStore(store);

export { store, persistor };
