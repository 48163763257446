import React,{useState, useEffect} from "react";
import { Link } from "react-router-dom";

import * as Icon from 'react-feather'
import {AiOutlineCamera,BsHeartbreak} from '../../../assets/icons/vander'

import backgroundImage from '../../../assets/images/blog/bg.jpg'
import circleLogo from '../../../assets/images/client/circle-logo.png'
import facebookLogo from '../../../assets/images/client/facebook-logo-2019.png'
import spotifyLogo from '../../../assets/images/client/spotify.png'
import portfolio1 from '../../../assets/images/portfolio/1.jpg'
import portfolio2 from '../../../assets/images/portfolio/2.jpg'
import portfolio3 from '../../../assets/images/portfolio/3.jpg'
import portfolio4 from '../../../assets/images/portfolio/4.jpg'
import portfolio5 from '../../../assets/images/portfolio/5.jpg'
import portfolio6 from '../../../assets/images/portfolio/6.jpg'

import UserProfileTab from "./userProfileTab";

import Lightbox from 'react-18-image-lightbox';
import "react-18-image-lightbox/style.css"
import {storeApi,common, adminApi} from '../../../api/index'
import default_store_img from '../../../assets/images/default_profile_black_v3.png';
import default_back_img from '../../../assets/images/default_back_img.png';
import { useParams } from 'react-router-dom';
import {
    Menu,
    MenuHandler,
    MenuList,
    MenuItem,
    Button
  } from "@material-tailwind/react";
  import { useNavigate } from 'react-router-dom';
  import { useToast } from '@chakra-ui/react';

export default function StoreDetails({onRefreshData}){
    const navigate = useNavigate();
    const [proImg, setProImg] = useState("");
    const [backImg, setBackImg] = useState("");
    const [storeInfo, setStoreInfo] = useState({});
    const { status,id } = useParams();
    const toast = useToast()
    const [city,setCity] = useState('');
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [isOpen, setisOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const [modalAppr, setmodalAppr] = useState(false);
    // const handleOpen = () => setOpen((cur) => !cur);
    
    useEffect(() => {
        document.documentElement.setAttribute("dir", "ltr");
        document.documentElement.classList.add('light');
        document.documentElement.classList.remove('dark');
        storeApi.getProfileImg({store_id:id})
        .then(res=>{
          setProImg(`https://api.sure2shops.com/${res.data}`)
        })
        .catch(err=>{
          setProImg(``)
        })
        storeApi.getBackgroundImg({store_id:id})
        .then(res=>{
          setBackImg(`https://api.sure2shops.com/${res.data}`)
        })
        .catch(err=>{
          setBackImg(``)
        })

        if(status === "pending"){
            //search in temp store_list table
            storeApi.singleTempInfo({store_id:id})
            .then(res=>{
                console.log(res.data)
                setStoreInfo(res.data)
            })
            .catch(err=>{
                console.log(err?.response?.message)
            })
        }
        else{
            //search in store_list table
            storeApi.singleStoreInfo({store_id:id})
            .then(res=>{
                console.log(res.data)
                setStoreInfo(res.data)
            })
            .catch(err=>{
                console.log(err?.response?.message)
            })
        }
    }, []);
    
    useEffect(() => {
      //get city name
      let cityName = ""
      common.getCityList()
      .then(async res=> {
        //english
          const answersRequest = () => Promise.all(res.data.map(item => {
            if(item.city_id === storeInfo?.city_id){
              cityName = item.city_name_eng
            }
          }))
          answersRequest().then(()=>{
            setCity(cityName)
          })
      })
      .catch(err => {
        console.log(err)
      })

    }, [storeInfo?.city_id]);


    const images = [portfolio1, portfolio2, portfolio3, portfolio4, portfolio5, portfolio6]

    const handleMovePrev = () => {
        setCurrentImageIndex((prevIndex) => (prevIndex + images.length - 1) % images.length);
    };

    const handleMoveNext = () => {
        setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    };
    const handleImageClick = (index) => {
        setCurrentImageIndex(index);
        setisOpen(true);
    };
    const currentImage = images[currentImageIndex];

    const experienceData = [
        {
            image:circleLogo,
            title :'Senior Web Developer',
            experience :'3 Years Experience',
            company:'CircleCi',
            location:'@London, UK'
        },
        {
            image:facebookLogo,
            title :'Web Designer',
            experience :'2 Years Experience',
            company:'Facebook',
            location:'@Washington D.C, USA'
        },
        {
            image:spotifyLogo,
            title :'UI Designer',
            experience :'2 Years Experience',
            company:'Spotify',
            location:'@Perth, Australia'
        },
    ]

    const portfolioData = [
        {
            id:0,
            image:portfolio1,
            title1:'Mockup Collection',
            title2:'Abstract'
        },
        {
            id:1,
            image:portfolio2,
            title1:'Mockup Collection',
            title2:'Abstract'
        },
        {
            id:2,
            image:portfolio3,
            title1:'Mockup Collection',
            title2:'Abstract'
        },
        {
            id:3,
            image:portfolio4,
            title1:'Mockup Collection',
            title2:'Abstract'
        },
        {
            id:4,
            image:portfolio5,
            title1:'Mockup Collection',
            title2:'Abstract'
        },
        {
            id:5,
            image:portfolio6,
            title1:'Mockup Collection',
            title2:'Abstract'
        },
    ]

    const ToastPromise=(title,message,request)=>{
        toast.promise(request, {
            success: { title: title, description: message, position:'top-right', duration: 9000,isClosable: true},
            error: { title: 'ERROR', description: ((errorMessage)?errorMessage:'An issue occured with your request'), position:'top-right', duration: 9000,isClosable: true },
            loading: { title: 'Please wait', description: 'Processing...', position:'top-right', duration: 9000,isClosable: true },
          })
    }

    const approveTemp = () => {
        const myPromise = new Promise((resolve, reject) => {
            adminApi.approveTempStore({store_id:storeInfo.store_id})
            .then(res=>{
                setmodalAppr(!modalAppr)
                navigate(`/store-profile/active/${storeInfo.store_id}/store-details`)
                onRefreshData()
                storeApi.singleStoreInfo({store_id:id})
                .then(res=>{
                    console.log(res.data)
                    setStoreInfo(res.data)
                    resolve()
                })
                .catch(err=>{
                    console.log(err?.response?.message)
                    setErrorMessage(err?.response?.message)
                    reject()
                })
            })
            .catch(err=>{
                console.log(err)
                setErrorMessage('An issue occured with your request.')
                reject()
            })
        })
        ToastPromise('Store Approved',`${storeInfo.store_name_eng} Store approved successfully`,myPromise)
    }

    const deleteTemp = () => {
        const myPromise = new Promise((resolve, reject) => {
            storeApi.deleteStoretemp({store_id:storeInfo.store_id})
            .then(deletedStoreTemp_res=> {
              resolve()
              navigate(`/stores`)
            })
            .catch(err=> {
                console.log(err?.response?.data)
                setErrorMessage("An issue occured with your request")
                reject()
            })
        })
        ToastPromise('Store Deleted',`${storeInfo.store_name_eng} Store deleted successfully`,myPromise)
      }
    
    return(
        <>
        {/* <div className="container-fluid relative px-3">
            <div className="layout-specing">
                <div className="grid grid-cols-1">
                    <div className="profile-banner relative text-transparent rounded-md shadow dark:shadow-gray-700 overflow-hidden">
                        <input id="pro-banner" name="profile-banner" type="file" className="hidden"/>
                        <div className="relative shrink-0">
                            <img src={(backImg)? backImg : default_back_img} className="h-80 w-full object-cover" id="profile-banner" alt=""/>
                            <div className="absolute inset-0 bg-black/70"></div>
                            <label className="absolute inset-0 cursor-pointer" htmlFor="pro-banner"></label>
                        </div>
                    </div>
                </div> */}

                {/* <div className="grid md:grid-cols-12 grid-cols-1"> */}
                    {/* <UserProfileTab profileImg={(proImg)? proImg : default_store_img} storeData={storeInfo}/> */}
                    <div className="xl:col-span-9 lg:col-span-8 md:col-span-8 mt-6">
                        <div className="grid grid-cols-1 gap-6">
                            {/* <div className="p-6 relative rounded-md shadow dark:shadow-gray-700 bg-white dark:bg-slate-900">
                                <h5 className="text-xl font-semibold">Cristina Murfy</h5>
                    
                                <p className="text-slate-400 mt-3">I have started my career as a trainee and prove my self and achieve all the milestone with good guidance and reach up to the project manager. In this journey, I understand all the procedure which make me a good developer, team leader, and a project manager.</p>
                            </div> */}

                            <div className="grid lg:grid-cols-1 grid-cols-1 gap-6">
                                <div className="p-6 relative rounded-md shadow dark:shadow-gray-700 bg-white dark:bg-slate-900 h-fit">
                                    <span className="flex-inline">
                                        <h5 className="text-xl font-semibold inline-text">Personal Details :</h5>
                                        {(status==="pending")&&(<Menu>
                                            <MenuHandler>
                                                <Button>Actions</Button>
                                            </MenuHandler>
                                            <MenuList>
                                                {(status==="pending")&&(<MenuItem onClick={()=>setmodalAppr(true)}>Approve</MenuItem>)}
                                                {(status==="pending")&&(<MenuItem onClick={()=>deleteTemp()}>Delete</MenuItem>)}
                                            </MenuList>
                                        </Menu>)}
                                    </span>

                                    <div className="mt-6 grid lg:grid-cols-2 grid-cols-2">
                                        
                                        <div className="">
                                            <div className="flex items-center mt-3">
                                                {/* <Icon.Bookmark className="fea icon-ex-md text-slate-400 me-3"/> */}
                                                <div className="flex-1">
                                                    <h6 className="text-indigo-600 dark:text-white font-medium mb-0">Store ID :</h6>
                                                    <Link to="" className="text-slate-400">{storeInfo.store_id}</Link>
                                                </div>
                                            </div>
                                            <div className="flex items-center mt-3">
                                                {/* <Icon.Bookmark className="fea icon-ex-md text-slate-400 me-3"/> */}
                                                <div className="flex-1">
                                                    <h6 className="text-indigo-600 dark:text-white font-medium mb-0">Store name (EN) :</h6>
                                                    <Link to="" className="text-slate-400">{storeInfo.store_name_eng}</Link>
                                                </div>
                                            </div>
                                            <div className="flex items-center mt-3">
                                                {/* <Icon.Bookmark className="fea icon-ex-md text-slate-400 me-3"/> */}
                                                <div className="flex-1">
                                                    <h6 className="text-indigo-600 dark:text-white font-medium mb-0">Store name (KU) :</h6>
                                                    <Link to="" className="text-slate-400">{storeInfo.store_name_kurdish}</Link>
                                                </div>
                                            </div>
                                            <div className="flex items-center mt-3">
                                                {/* <Icon.Bookmark className="fea icon-ex-md text-slate-400 me-3"/> */}
                                                <div className="flex-1">
                                                    <h6 className="text-indigo-600 dark:text-white font-medium mb-0">Store name (AR) :</h6>
                                                    <Link to="" className="text-slate-400">{storeInfo.store_name_arabic}</Link>
                                                </div>
                                            </div>
                                            <div className="flex items-center">
                                                {/* <Icon.Mail className="fea icon-ex-md text-slate-400 me-3"/> */}
                                                <div className="flex-1">
                                                    <h6 className="text-indigo-600 dark:text-white font-medium mb-0">Store type :</h6>
                                                    <Link to="" className="text-slate-400">{(storeInfo.isIndividual) ? "Individual" : "Store"}</Link>
                                                </div>
                                            </div>
                                            <div className="flex items-center">
                                                {/* <Icon.Mail className="fea icon-ex-md text-slate-400 me-3"/> */}
                                                <div className="flex-1">
                                                    <h6 className="text-indigo-600 dark:text-white font-medium mb-0">Email :</h6>
                                                    <Link to="" className="text-slate-400">{(storeInfo.store_email) ? storeInfo.store_email : "N/A"}</Link>
                                                </div>
                                            </div>
                                            <div className="flex items-center mt-3">
                                                {/* <Icon.Italic className="fea icon-ex-md text-slate-400 me-3"/> */}
                                                <div className="flex-1">
                                                    <h6 className="text-indigo-600 dark:text-white font-medium mb-0">Payment method :</h6>
                                                    <Link to="" className="text-slate-400">{(storeInfo.store_payment_options) ? storeInfo.store_payment_options : "N/A"}</Link>
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <div>
                                        <div className="flex items-center mt-3">
                                            {/* <Icon.Globe className="fea icon-ex-md text-slate-400 me-3"/> */}
                                            <div className="flex-1">
                                                <h6 className="text-indigo-600 dark:text-white font-medium mb-0">Creation date :</h6>
                                                <Link to="" className="text-slate-400">
                                                    {
                                                        new Date(`${storeInfo.createdAt}`).toLocaleDateString(
                                                        'en-gb',
                                                        {
                                                        year: 'numeric',
                                                        month: 'long',
                                                        day: 'numeric',
                                                        timeZone: 'utc'
                                                        }
                                                    )}
                                                </Link>
                                            </div>
                                        </div>
                                        <div className="flex items-center mt-3">
                                            {/* <Icon.Globe className="fea icon-ex-md text-slate-400 me-3"/> */}
                                            <div className="flex-1">
                                                <h6 className="text-indigo-600 dark:text-white font-medium mb-0">Last modification date :</h6>
                                                <Link to="" className="text-slate-400">
                                                    {
                                                        new Date(`${storeInfo.updatedAt}`).toLocaleDateString(
                                                        'en-gb',
                                                        {
                                                        year: 'numeric',
                                                        month: 'long',
                                                        day: 'numeric',
                                                        timeZone: 'utc'
                                                        }
                                                    )}
                                                </Link>
                                            </div>
                                        </div>
                                        <div className="flex items-center mt-3">
                                            {/* <Icon.Gift className="fea icon-ex-md text-slate-400 me-3"/> */}
                                            <div className="flex-1">
                                                <h6 className="text-indigo-600 dark:text-white font-medium mb-0">Ad status :</h6>
                                                <p className="text-slate-400 mb-0">{storeInfo.store_ad_status}</p>
                                            </div>
                                        </div>
                                        <div className="flex items-center mt-3">
                                            {/* <Icon.MapPin className="fea icon-ex-md text-slate-400 me-3"/> */}
                                            <div className="flex-1">
                                                <h6 className="text-indigo-600 dark:text-white font-medium mb-0">City :</h6>
                                                <Link to="" className="text-slate-400">{city}</Link>
                                            </div>
                                        </div>
                                        <div className="flex items-center mt-3">
                                            {/* <Icon.Gift className="fea icon-ex-md text-slate-400 me-3"/> */}
                                            <div className="flex-1">
                                                <h6 className="text-indigo-600 dark:text-white font-medium mb-0">Address :</h6>
                                                <p className="text-slate-400 mb-0">{storeInfo.store_address}</p>
                                            </div>
                                        </div>
                                        <div className="flex items-center mt-3">
                                            {/* <Icon.Phone className="fea icon-ex-md text-slate-400 me-3"/> */}
                                            <div className="flex-1">
                                                <h6 className="text-indigo-600 dark:text-white font-medium mb-0">Phone number :</h6>
                                                <Link to="" className="text-slate-400">{storeInfo.store_phone_no}</Link>
                                            </div>
                                        </div>
                                        </div>

                                    </div>
                                </div>

                                {/* <div className="p-6 relative rounded-md shadow dark:shadow-gray-700 bg-white dark:bg-slate-900 h-fit">
                                    <h5 className="text-xl font-semibold">Actions :</h5>
                                    {experienceData.map((item, index) =>{
                                        return(
                                            <div key={index} className="flex duration-500 hover:scale-105 shadow dark:shadow-gray-700 hover:shadow-md dark:hover:shadow-gray-700 ease-in-out items-center p-4 rounded-md bg-white dark:bg-slate-900 mt-6">
                                                <img src={item.image} className="h-16 w-16 p-4 bg-slate-50 dark:bg-slate-800 shadow dark:shadow-gray-700 rounded-md" alt=""/>
                                                <div className="flex-1 content ms-4">
                                                    <h4 className="text-lg text-medium">{item.title}</h4>
                                                    <p className="text-slate-400 mb-0">{item.experience}</p>
                                                    <p className="text-slate-400 mb-0"><Link to="" className="text-indigo-600">{item.company}</Link> {item.location}</p>    
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div> */}
                            </div>

                            <div className="p-6 relative rounded-md shadow dark:shadow-gray-700 bg-white dark:bg-slate-900">
                                <h5 className="text-xl font-semibold">Products :</h5>
            
                                <div className="grid lg:grid-cols-3 md:grid-cols-2 mt-6 gap-6">
                                    {portfolioData.map((item,index) => {
                                        return(
                                            <div className="group relative block overflow-hidden rounded-md duration-500" key={index}>
                                                <img src={item.image} className="group-hover:origin-center group-hover:scale-110 group-hover:rotate-3 duration-500" alt=""/>
                                                <div className="absolute inset-2 group-hover:bg-white/90 dark:group-hover:bg-slate-900/90 duration-500 z-0 rounded-md"></div>
                    
                                                <div className="content duration-500">
                                                    <div className="icon absolute z-10 opacity-0 group-hover:opacity-100 top-6 end-6 duration-500">
                                                        <Link to="#" onClick={() => handleImageClick(item.id)} className="h-9 w-9 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-full lightbox"><AiOutlineCamera/></Link>
                                                    </div>
                    
                                                    <div className="absolute z-10 opacity-0 group-hover:opacity-100 bottom-6 start-6 duration-500">
                                                        <Link to="/portfolio-detail-three" className="h6 text-lg font-medium hover:text-indigo-600 duration-500 ease-in-out">{item.title1}</Link>
                                                        <p className="text-slate-400 mb-0">{item.title2}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                    {isOpen && (
                                        <Lightbox
                                            mainSrc={currentImage}
                                            prevSrc={images[(currentImageIndex + images.length - 1) % images.length]}
                                            nextSrc={images[(currentImageIndex + 1) % images.length]}

                                            onCloseRequest={() => setisOpen(false)}
                                            onMovePrevRequest={handleMovePrev}
                                            onMoveNextRequest={handleMoveNext}
                                        />
                                     )}
                                     
                                </div>  
                            </div>
                        </div>
                    </div>
                {/* </div> */}
            {/* </div>
        </div> */}

        <div className="" id="modals">            
            <div className="p-5 border-t border-gray-100 dark:border-slate-800">
                <div className={`bg-gray-900 bg-opacity-50 dark:bg-opacity-80 fixed inset-0 z-40 flex items-center justify-center ${modalAppr ? '' : 'hidden'}`}>
                    <div className="relative w-full h-auto max-w-lg p-4">
                        <div className="relative bg-white dark:bg-slate-900 rounded-lg shadow dark:shadow-gray-800">
                            <button type="button" onClick={() => setmodalAppr(!modalAppr)} className="absolute -top-4 -end-4 text-gray-400 bg-white dark:bg-slate-900 shadow dark:shadow-gray-800 hover:text-gray-900 rounded-full text-sm p-1.5 ms-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white" >
                                <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>  
                            </button>
                            <div className="p-6 py-10 text-center">           
                                <h4 className="text-xl font-semibold mt-6">Are you sure you want to approve this temp store?</h4>
                                <Link to="#" onClick={()=>{
                                    setmodalAppr(false)
                                }} className="py-[5px] px-4 inline-block font-semibold tracking-wide align-middle duration-500 text-sm text-center bg-transparent hover:bg-indigo-600 border border-indigo-600 text-indigo-600 hover:text-white rounded-md mt-2">Cancel</Link>
                                <Link to="#" onClick={()=>approveTemp()} className="py-[5px] px-4 inline-block font-semibold tracking-wide align-middle duration-500 text-sm text-center bg-transparent hover:bg-indigo-600 border border-indigo-600 text-indigo-600 hover:text-white rounded-md mt-2 margin-left-sm">Approve</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}